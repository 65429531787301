export const selectInput = (array, key, value, isFixed) => {
    let newArray = [];
    array.map((item) => {
      const x = {
        value: item[key],
        label: item[value],
        isFixed: item[isFixed] ? item[isFixed] : false,
      };
      return newArray.push(x);
    });
  
    return newArray;
  };
  
  export const selectValueFormat = (value,label) => {
    if (!value) return null;
    return { value: value, label: label };
  };
  
  export const formatAId = (aId) => {
    return aId.match(/.{1,3}/g).join("-");
  };
  
  export const uppercaseFirstLetter = (input) => {
    const formatedInput = input.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    return formatedInput;
  }
  
  export const formatPhoneNumber = (number) => {
    let formatedNumber = number;
    if (formatedNumber.startsWith("0")) {
      formatedNumber = number.replace("0", "");
      return formatedNumber
    }
    else {
      return formatedNumber
    }
  };
  
  export const passportNumberIsValid = (passportNumber) => {
    let regex = new RegExp(/^[0-9a-zA-Z\s\-\/]{6,20}$/);
    return regex.test(passportNumber);
  }
  
  
  
  export const phoneNumberIsValid = ({ countryCode, number }) => {
    let regex = new RegExp(/^[+]{1}(?:[0-9\-\(\)\/\.]\s?){9,15}[0-9]{1}$/);
    return regex.test(countryCode + number);
  }
  
  export const cutWords = (word) => {
    if (word && word.length > 10) {
      return word.slice(0, 10) + "...";
    } else {
      return word;
    }
  };
  
  export const emailIsInvalid = (email) => {
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  
    if (email !== null && email.match(emailRegex) === null) {
      return true;
    } else {
      return false;
    }
  };
  
  export const formatDate = (inputDate) => {
    if (!inputDate) return "";
    const date = new Date(inputDate);
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1;
    let dd = date.getDate();
  
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
  
    const formattedDate = dd + "/" + mm + "/" + yyyy;
    return formattedDate;
  };
  
  export const longDateformat = (inputDate) => {
    const date = new Date(inputDate);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
  
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
  
    return day + " " + monthNames[monthIndex] + " " + year;
  };
  export const renderTodayOrYesterday = (date) => {
    const currentDate = new Date();
    const givenDate = new Date(date);
    const isToday =
      givenDate.getDate() === currentDate.getDate() &&
      givenDate.getMonth() === currentDate.getMonth() &&
      givenDate.getFullYear() === currentDate.getFullYear();
  
    const isYesterday =
      givenDate.getDate() === currentDate.getDate() - 1 &&
      givenDate.getMonth() === currentDate.getMonth() &&
      givenDate.getFullYear() === currentDate.getFullYear();
      
      const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
      }
      const formatDate = (date) => {
        return [
          padTo2Digits(date.getDate()),
          padTo2Digits(date.getMonth() + 1),
          date.getFullYear(),
        ].join('/');
      }
    if (isToday) {
      return <span> Today, {givenDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} </span>;
    } else if (isYesterday) {
      return <span> Yesterday, {givenDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>;
    } else {
      return <span>{formatDate(givenDate)} {","} {givenDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
      </span>;
    }
  }
  
  export const revervseDate = (date) => {
    let newDate = date?.split("/")
    return newDate[2] + "-" + newDate[1] + "-" + newDate[0]
  }
  
  export const formatDateToAPI = (inputDate) => {
    if (!inputDate) return "";
    let [d, m, y] = inputDate.split("/");
    if (+d < 10) d = "0" + +d;
    if (+m < 10) m = "0" + +m;
    return `${y}-${m}-${d}`;
  };
  
  export const subtractDaysFromDate = (dateStr, days) => {
    var date = new Date(revervseDate(dateStr));
    date.setDate(date.getDate() - days);

    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();

    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    return `${day}/${month}/${year}`;
}


  export const formatDateFromAPI = (inputDate) => {
    if (!inputDate) return "";
    let [y, m, d] = inputDate.split("-");
    if (+d < 10) d = "0" + +d;
    if (+m < 10) m = "0" + +m;
    return `${d}/${m}/${y}`;
  };
  
  export const valueNull = (value) => {
    if (!value) return "-";
    else return value;
  };
  
  export const formatTime = (inputDate) => {
    const time = new Date(inputDate).toLocaleTimeString("en", {
      timeStyle: "short",
      hour12: true,
      timeZone: "UTC",
    });
    return time;
  };
  
  
  export const  shortenString = (str, maxLength) => {
    if(str?.length >= maxLength) {
      let result = str.slice(0,maxLength) + "..."
      return result
    }
    return str
       
  }
  
  export const shortenDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const time = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    return time;
  };
  export const truncateUrl = (url) => {
    const maxLength = 20;
    if (url.length <= maxLength) {
      return url;
    }
    const truncatedUrl = url.slice(0, maxLength) + "...";
    return truncatedUrl;
  };
  
  
  // Number.prototype.padLeft = function (base, chr) {
  //   var len = (String(base || 10).length - String(this).length) + 1;
  //   return len > 0 ? new Array(len).join(chr || '0') + this : this;
  // }
  
  // export const formatDateAndTime = (inputDate) => {
  //   const d = new Date(inputDate);
  //   const dformat  = [(d.getMonth() + 1).padLeft(),
  //   d.getDate().padLeft(),
  //   d.getFullYear()].join('/') + ' ' +
  //     [d.getHours().padLeft(),
  //     d.getMinutes().padLeft()].join(':');
  
  //   return dformat;
  // }
  
  
  export const uppercaseFirstLetterWord = (str) => {
   let result = str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();
   return result;
  };

  export const convertFromSnakeCase = (str) => {
    return str?.toLowerCase()?.split("_")
    ?.filter(x => x.length > 0)
    ?.map((x) => (x.charAt(0).toUpperCase() + x.slice(1)))
    ?.join(" ");
  }

  export const formatDateAndTime = (inputDate) => {
    var newDate = new Date(inputDate);
  
    var sMonth = padValue(newDate.getMonth() + 1);
    var sDay = padValue(newDate.getDate());
    var sYear = newDate.getFullYear();
    var sHour = newDate.getHours();
    var sMinute = padValue(newDate.getMinutes());
    var sAMPM = "AM";
  
    var iHourCheck = parseInt(sHour);
  
    if (iHourCheck > 12) {
      sAMPM = "PM";
      sHour = iHourCheck - 12;
    }
    else if (iHourCheck === 0) {
      sHour = "12";
    }
  
    sHour = padValue(sHour);
  
    return sDay + "/" + sMonth + "/" + sYear + " " + sHour + ":" + sMinute + " " + sAMPM;
  }
  
  function padValue(value) {
    return (value < 10) ? "0" + value : value;
  }
  
  export const formatAMPM = (inputDate) => {
    const date = new Date(inputDate);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    const time = date.toLocaleString([], {
      hour: '2-digit',
      minute: '2-digit'
    });
    return time
  }
  
  export const compareDates = (date, timeSlot) => {
    const dateParts = date.split("/");
    let mainDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    let todaysDate = new Date();
    if (mainDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)) {
      let inputDate = new Date(timeSlot)
      inputDate.setMinutes(inputDate.getMinutes() + inputDate.getTimezoneOffset())
      const inputTime = inputDate.toLocaleString([], {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit'
      });
      const currentTime = new Date().toLocaleString([], {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit'
      });
  
      if (inputTime <= currentTime) {
        return true
      }
      else {
        return false
      }
    }
    else {
      return false
    }
  }
  
  //[{label: 1, value: 1} ]==> [1]
  export const convertSelectIntoArr = (arr) => {
    return arr?.map((obj) => {
      return obj?.value;
    });
  };
  
  //[1] ==> [{label: 1, value: 1}]
  export const convertArrIntoSelect = (arr) => {
    return arr?.map((obj) => {
      return { label: obj, value: obj };
    });
  };
  
  
  export const dateDiffInDays = (date1, date2) => {
    // Convert both dates to UTC
    const [day1, month1, year1] = date1.split('/');
    const [day2, month2, year2] = date2.split('/');
  
    // Create Date objects using the parsed values (subtract 1 from month since it's zero-based)
    const utcDate1 = new Date(Date.UTC(year1, month1 - 1, day1));
    const utcDate2 = new Date(Date.UTC(year2, month2 - 1, day2));
  
    // Calculate the difference in milliseconds
    const diffInMs = utcDate2 - utcDate1;
  
    // Convert the difference from milliseconds to days
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  
    return diffInDays;
  }

  export const addAllToSelect = (arr) => {
    return [{ label: "All Countries", value: "ALL" }, ...arr];
  }

  export const convertCountryIntoCode = (country) => {
    if(country === "Egypt") {
      return "EG"
    }
    if(country === "Saudi Arabia") {
      return "SA"
    }
  }

  export const validateNationalID = (nationalId, date, genderValue) => {
    if (!/^\d{14}$/.test(nationalId)) {
        return { valid: false, error: 'National Id must be 14 digits long' };
    }

    const centuryDigit = parseInt(nationalId[0], 10);
    const year = (centuryDigit === 2 ? 1900 : 2000) + parseInt(nationalId.slice(1, 3), 10);
    const month = parseInt(nationalId.slice(3, 5), 10);
    const day = parseInt(nationalId.slice(5, 7), 10);
    const serial = nationalId.slice(9, 13);

    const birthDate = new Date(formatDateToAPI(date));
    if (birthDate.getFullYear() !== year || birthDate.getMonth() + 1 !== month || birthDate.getDate() !== day) {
        return { valid: false, error: 'Invalid birthdate in National Id' };
    }

    const gender = parseInt(serial, 10) % 2 === 0 ? 'FEMALE' : 'MALE';
     if(gender!==genderValue)
     {
        return { valid: false, error: 'Invalid Gender' };

     }
    return {
        valid: true,
        birthdate: `${day}/${month}/${year}`,
        gender,
    };
};