import { Box, IconButton, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { useState } from "react";
import Menu from '@mui/material/Menu';
import classes from "./UserDropDown.module.scss";
import words from "../../assets/words";
import { IoIosArrowDown } from "react-icons/io";
import avatar from "../../assets/images/avatar.png"
import { TbLogout } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { LOGOUT } from "../../graphql/Mutations";
import { authActions } from "../../store"

const UserDropDown = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { language, user } = useSelector((state) => state.auth);
    const [anchorEl, setAnchorEl] = useState(false);
    const [openEvent, setOpenEvent] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event?.currentTarget)
        setOpenEvent(!openEvent)
    };



      const [logout] = useMutation(LOGOUT, {
        onCompleted: (data) => {
          if (data.logoutCSUser.success) {
            sessionStorage.removeItem("token")
            dispatch(authActions.logout());
            navigate("/auth")
            setOpenEvent(false);
          }
        }
      }
      )



    return (
        <div className={classes.profileInfoContainer}>

            <IconButton
                aria-label="menu"
                color="inherit"
                aria-controls="profile-menu"
                aria-haspopup="true"
                onClick={handleClick}
                style={{ borderRadius: "5px", gap: '10px', }}
            >
                <Box style={{
                    gap: '10px',
                }} display="flex" alignItems="center">
                    {/* {
            userData?.profilePictureUrl &&
            <img src={userData?.profilePictureUrl} alt="user-pic" style={{ width: "40px", height: "40px", borderRadius: "50%", objectFit: "cover" }} />
          } */}
                    <img src={avatar} alt="user-pic" style={{ width: "40px", height: "40px", borderRadius: "50%", objectFit: "cover" }} />

                    <Box
                        sx={{
                            display: {
                                xs: "none",
                                sm: "flex",
                            },

                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <span
                            className={classes.name}
                            style={{
                                whiteSpace: "nowrap",
                                fontSize: "16px",
                                fontWeight: "bold"
                            }}
                        >
                            {/* {userData?.fullName && uppercaseFirstLetter(userData?.fullName)}   <IoIosArrowDown className="ms-2" size={15} /> */}
                            {user?.firstName} {user?.lastName} <IoIosArrowDown className="ms-2" size={15} />
                        </span>
                    </Box>
                </Box>
            </IconButton>
            <Menu
                id="profile-menu"
                anchorEl={anchorEl}
                keepMounted
                open={openEvent}
                onClose={handleClick}
                sx={{
                    "& .MuiMenu-paper": {
                        width: "220px",
                        borderRadius: "10px",
                        fontFamily: "Poppins",
                        color: "var(--primaryColor)"
                    },
                    "& .MuiList-padding": {
                        p: "15px",
                    },
                }}
            >
                <MenuItem
                    sx={{
                        pt: 1,
                        pb: 1,
                        pl: 1,
                        pr: 1,
                    }}
                    onClick={()=>logout()}
                    style={{
                        fontFamily: "Poppins",
                        fontWeight: "700px"
                    }}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        gap={'10px'}
                    >
                        <TbLogout size={20} /> {words.LOG_OUT[language]}
                    </Box>
                </MenuItem>
            </Menu>
        </div>

    );
};

export default UserDropDown;
