import { useSelector } from "react-redux";
import success from "../../assets/images/successCheck.png"
import Button from "./Button";
import classes from "./SuccessMessage.module.scss";
import words from "../../assets/words";

const SuccessMessage = ({ message, children, onDone }) => {
    const {language} = useSelector(state => state.auth)

    return (
        <div className={classes.container}>
            <img src={success} alt="Success" />

            <p>{message}</p>
            <div className={classes.actions}>
                {children ||
                <div>
                    <Button onClick={onDone}>{words.DONE[language]}</Button>
                </div>
                }
            </div>

        </div>
    )
}

export default SuccessMessage;