import words from '../../assets/words';
import { formatTime } from '../../util/formatWords';
import classes from './TicketInfo.module.scss'
import { useSelector } from 'react-redux';

const TicketInfo = ({ ticketInfo, style, displayTicketNum ,showTime=true}) => {
    const { language } = useSelector((state) => state.auth);
    const formattedDate = ticketInfo?.createdAt ? new Date(ticketInfo?.createdAt).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
    }) : "";
    return (<div className={classes.ticketInfoContainer} style={style}>
        <div className={classes.ticketInfo}>
            <div>
                <span className={classes.name}>{ticketInfo?.customerId ? ticketInfo.customerId?.firstName + ' ' + ticketInfo?.customerId?.lastName : 
                ticketInfo?.employeeId ? ticketInfo.employeeId?.firstName + ' ' + ticketInfo?.employeeId?.lastName : ''}</span>
                <span className={classes.aId}>{ ticketInfo?.customerId?.aId ?' . ' + ticketInfo.customerId.aId : ''}</span>
                <span className={classes.date}>  {' . ' + formattedDate} {showTime&&<span>{' at '+formatTime(ticketInfo?.createdAt)}</span>}</span>
                <span className={classes.source}>{' . ' + ticketInfo ? ticketInfo?.source?.value : ''}</span>
            </div>

            {
                displayTicketNum &&
                <div className={classes.ticketNumber}>
                    {words.TICKET[language]} <span>{ticketInfo ? " (" + ticketInfo?.ticketNumber + ")" : ''}</span>
                </div>
            }

        </div>

        <div className={classes.description}>
            <span className={classes.ticketReason}> {ticketInfo?.ticketReason ? ticketInfo?.ticketReason?.value : ""}</span>
            <br />
            {ticketInfo?.comment ? ticketInfo?.comment : ""}

        </div>


    </div>)
}

export default TicketInfo