import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import words from "../../assets/words";
import classes from "./BreadCrumb.module.scss";
import { MdKeyboardArrowRight } from "react-icons/md";

const BreadCrumb = () => {
  const language = useSelector((state) => state.auth.language);
  const locatin = useLocation().pathname;
  const [currentTicketDetailsUrl, setCurrentTicketDetailsUrl] = useState('')
  const [breadcrumbs, setBreadcrumbs] = useState([])

  const Menuitems = [
    {
      header: words.DASHBOARD[language],
      title: "",
      href: ""
    },
    {
      header: words.HOME[language],
      title: words.NEW_TICKETS[language],
      href: "/new-tickets"
    },
    {
      header: words.HOME[language],
      title: words.ALL_TICKETS[language],
      href: "/all-tickets"
    },
    {
      header: words.HOME[language],
      title: words.ORGANIZATIONS[language],
      href: "/organizations"
    },
    {
      header: words.HOME[language],
      title: words.CLIENTS[language],
      href: "/clients"
    },
    {
      header: words.HOME[language],
      title: words.NOTIFICATIONS[language],
      href: "/notifications"
    },

  ]

  const queryParams = new URLSearchParams(useLocation().search);
  const status = queryParams.get('status')
  useEffect(() => {
    Menuitems.map((item) => {
      if (item.href === '/new-tickets' && locatin.startsWith('/new-tickets/') && !locatin.includes('all-emails')) {
        setCurrentTicketDetailsUrl(locatin)
        setBreadcrumbs([
          <Link underline="hover" key="1" color="inherit" to='/'>
            {item.header}
          </Link>,
          <Link underline="hover" key="2" color="inherit" to={`${item.href}`}>
            {item.title}
          </Link>,
          ,
          <p key="3" color="text.primary">
            {words.TICKET_DETAILS[language]}
          </p>

        ])

      } else if (item.href === '/all-tickets' && locatin.startsWith('/all-tickets/') && !locatin.includes('all-emails')) {
        setCurrentTicketDetailsUrl(locatin)
        setBreadcrumbs([
          <Link underline="hover" key="1" color="inherit" to='/'>
            {item.header}
          </Link>,
          <Link underline="hover" key="2" color="inherit" to={`${item.href}`}>
            {item.title}
          </Link>,
          ,
          <p key="3" color="text.primary">
            {words.TICKET_DETAILS[language]}
          </p>

        ])
      }

      else if (item.href === '/organizations' && locatin.startsWith('/organizations/')) {
        setBreadcrumbs([
          <Link underline="hover" key="1" color="inherit" to='/'>
            {item.header}
          </Link>,
          <Link underline="hover" key="2" color="inherit" to={`${item.href}`}>
            {item.title}
          </Link>,
          <p key="3" color="text.primary">
            {words.BRANCHES[language]}
          </p>


        ])
      }
      else if (item.href.includes('all-tickets') && locatin.includes('all-emails') && !locatin.includes('new-tickets')&&status !== 'CLOSED') {
        setBreadcrumbs([
          <Link underline="hover" key="1" color="inherit" to='/'>
            {item.header}
          </Link>,
          <Link underline="hover" key="2" color="inherit" to={`${item.href}`}>
            {item.title}
          </Link>,
          <Link underline="hover" key="2" color="inherit" to={`${currentTicketDetailsUrl}`}>
            {words.TICKET_DETAILS[language]}
          </Link>,
          <p key="3" color="text.primary">
            {words.ALL_EMAILS[language]}
          </p>


        ])
      }
      else if (item.href.includes('all-tickets') && locatin.includes('all-emails') && status === 'CLOSED') {
        setBreadcrumbs([
          <Link underline="hover" key="1" color="inherit" to='/'>
            {item.header}
          </Link>,
          <Link underline="hover" key="2" color="inherit" to={`${item.href}`}>
            {item.title}
          </Link>,
          <p style={{ fontWeight: "500", cursor: "not-allowed" }}>
            {words.TICKET_DETAILS[language]}
          </p>,
          <p key="3" color="text.primary">
            {words.ALL_EMAILS[language]}
          </p>


        ])
      }

      else if (item.href.includes('new-tickets') && locatin.includes('all-emails')) {
        setBreadcrumbs([
          <Link underline="hover" key="1" color="inherit" to='/'>
            {item.header}
          </Link>,
          <Link underline="hover" key="2" color="inherit" to={`${item.href}`}>
            {item.title}
          </Link>,
          <Link underline="hover" key="2" color="inherit" to={`${currentTicketDetailsUrl}`}>
            {words.TICKET_DETAILS[language]}
          </Link>,
          <p key="3" color="text.primary">
            {words.ALL_EMAILS[language]}
          </p>


        ])
      }

      else if ((item.href === locatin || locatin.includes(item.href)) && !locatin.startsWith()) {
        setBreadcrumbs([
          <Link underline="hover" key="1" style={{ fontWeight: item.title === '' ? "600" : "500" }} color="inherit" aria-disabled={item.title === 'Dashboard'} to={item.header == "Home" ? '/' : item.href}>
            {item.header}
          </Link>,
          <p key="3" color="text.primary">
            {item.title}
          </p>

        ])
      }
    });
  }, [locatin, language]);

  return (
    <div className={classes.container}>
      {breadcrumbs.map((breadcrumb, index) => (
        <span key={index}>
          {breadcrumb}
          {(index < breadcrumbs.length - 1 && breadcrumbs[index + 1]?.props?.children !== '') ? <MdKeyboardArrowRight /> : null}
        </span>
      ))}
    </div>
  );
};

export default BreadCrumb;
