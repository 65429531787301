import { useLocation, useNavigate } from "react-router-dom";
import classes from "./TicketSidemenu.module.scss"
import { useEffect, useState } from "react";
import words from "../../assets/words";
import { useSelector } from "react-redux";

const TicketSidemenu = ({id}) => {
    const location = useLocation().pathname;
    const navigate = useNavigate()

    const { hasOrganizations } = useSelector((state) => state.ticketInfo);

    const [currentTab, setCurrentTab] = useState("")
    const { language } = useSelector(state => state.auth)
    const [tabs,setTabs]=useState([])

    useEffect(() => {
        setCurrentTab(location.split("/")[4]);
    }, [location])

    useEffect(()=>{
        if(hasOrganizations)
        {
            setTabs( [
                {
                    name: words.GENERAL_INFORMATION[language],
                    title: words.PERSONAL_INFORMATION[language],
                    href: "generalInfo"
                },
                {
                    name: words.MEMBERS_AND_CONNECTIONS[language],
                    title: "",
                    href: "members-and-connections"
                },
                {
                    name: words.REQUESTS[language],
                    title: "",
                    href: "requests"
                },
                {
                    name: words.SECURITY[language],
                    title: "",
                    href: "security"
                },
                {
                    name: words.ORGANIZATION_INFO[language],
                    title: words.ORGANIZATION_DATA[language],
                    href: "organizationInfo"
                },
                {
                    name: words.PACKAGE_DETAILS[language],
                    title: "",
                    href: "package-details"
                },
                {
                    name: words.BRANCHES[language],
                    title: "",
                    href: "branches"
                },
            ] )
        }else
        {
            setTabs( [
                {
                    name: words.GENERAL_INFORMATION[language],
                    title: words.PERSONAL_INFORMATION[language],
                    href: "generalInfo"
                },
                {
                    name: words.MEMBERS_AND_CONNECTIONS[language],
                    title: "",
                    href: "members-and-connections"
                },
                {
                    name: words.REQUESTS[language],
                    title: "",
                    href: "requests"
                },
                {
                    name: words.SECURITY[language],
                    title: "",
                    href: "security"
                },
            ])
        }
    },[hasOrganizations])
    return (
        <div className={classes.sideMenu}>
              {
                tabs.map((tab, index) => {
                    return (
                        <div key={index}>
                            {tab.title && <small>{tab.title}</small>}
                            <p className={tab.href === currentTab ? classes.activeTab : (id?.id === null && index !== 0) && classes.disabled} onClick={() => navigate(tab.href)}>
                                 <span>{tab.name}</span>
                            </p>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default TicketSidemenu