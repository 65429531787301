import { redirect } from "react-router-dom";

export function homeLoder() {
    const token = sessionStorage.getItem("token");
    if (!token) {
        return redirect("/auth")
    }
    else {
        return null;
    }
}

export function managerLoader() {
    const role = sessionStorage.getItem("role");
    if(role === "AGENT") {
        return redirect("/new-tickets")
    } else {
        return null;
    }
}

export function loginLoader() {
    const token = sessionStorage.getItem("token");
    if (token) {
        return redirect("/")
    }
    else {
        return null;
    }
}