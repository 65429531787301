import { Suspense, lazy } from "react";
import Spinner from "react-bootstrap/esm/Spinner";
import { createBrowserRouter } from "react-router-dom";
import MainLayout from "../components/layouts/MainLayout";
import TicketLayout from "../components/layouts/TicketLayout";
import { homeLoder, loginLoader, managerLoader } from "../util/auth";
// import { homeLoder } from "../util/auth"

const lazyRetry = function (componentImport) {
    return new Promise((resolve, reject) => {
        componentImport()
            .then((component) => {
                resolve(component);
            })
            .catch((error) => {
                window.location.reload();
                reject(error);
            });
    });
};

const Loading = (
    <div
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
        }}
    >
        <Spinner as="span" animation="border" variant="primary" />
    </div>
);


const LoginPage = lazy(() =>
    lazyRetry(() => import("../pages/auth/LoginPage"))
);

const DashboardPage = lazy(() =>
    lazyRetry(() => import("../pages/main/dashboard/DashboardPage"))
)

const NewTicketsPage = lazy(() =>
    lazyRetry(() => import("../pages/main/newTickets/NewTicketsPage"))
)

const AllTicketsPage = lazy(() =>
    lazyRetry(() => import("../pages/main/allTickets/AllTicketsPage"))
)

const OrganizationsPage = lazy(() =>
    lazyRetry(() => import("../pages/main/organizations/OrganizationsPage"))
)
const BranchesPage = lazy(() =>
    lazyRetry(() => import("../pages/main/organizations/BranchesPage"))
)

const ClientsPage = lazy(() =>
    lazyRetry(() => import("../pages/main/clients/ClientsPage"))
)

const NotificationsPage = lazy(() =>
    lazyRetry(() => import("../pages/main/notifications/NotificationsPage"))
)

const AllEmailsPage = lazy(() =>
    lazyRetry(() => import("../pages/main/allEmails/AllEmails"))
)

const GeneralInformation = lazy(() =>
    lazyRetry(() => import("../pages/main/ticketDetails/personalInfo/GeneralInformation"))
)

const OrganizationInfo = lazy(() =>
    lazyRetry(() => import("../pages/main/ticketDetails/organizationInfo/OrganizationInfo"))
)
const PackageDetails = lazy(() =>
    lazyRetry(() => import("../pages/main/ticketDetails/organizationInfo/PackageDetails"))
)
const Members = lazy(() =>
    lazyRetry(() => import("../pages/main/ticketDetails/personalInfo/Members"))
)
const Requests = lazy(() =>
    lazyRetry(() => import("../pages/main/ticketDetails/personalInfo/Requests"))
)
const Security = lazy(() =>
    lazyRetry(() => import("../pages/main/ticketDetails/personalInfo/Security"))
)
const Branches = lazy(() =>
    lazyRetry(() => import("../pages/main/ticketDetails/organizationInfo/Branches"))
)
const BranchesDetails = lazy(() =>
lazyRetry(() => import("../pages/main/ticketDetails/organizationInfo/BranchDetails"))
)



const router = createBrowserRouter([
    {
        path: "auth",
        loader: loginLoader,
        element:
            <Suspense fallback={Loading}>
                <LoginPage />
            </Suspense>,
    },
    {
        path: "",
        loader: homeLoder,
        element: <MainLayout />,
        children: [
            {
                index: true,
                loader: managerLoader,
                element: (
                    <Suspense fallback={Loading}>
                        <DashboardPage />
                    </Suspense>
                )
            },
            {
                path: "new-tickets",
                element: (
                    <Suspense fallback={Loading}>
                        <NewTicketsPage />
                    </Suspense>
                )
            },
            {
                path: "new-tickets/open/:ticketId",
                element: <TicketLayout />,
                children: [
                    {
                        path: "generalInfo",
                        element: (
                            <Suspense fallback={Loading}>
                                <GeneralInformation />
                            </Suspense>
                        )
                    },
                    {
                        path: "organizationInfo",
                        element: (
                            <Suspense fallback={Loading}>
                                <OrganizationInfo />
                            </Suspense>
                        )
                    },
                    {
                        path: "package-details",
                        element: (
                            <Suspense fallback={Loading}>
                                <PackageDetails />
                            </Suspense>
                        )
                    },
                    {
                        path: "members-and-connections",
                        element: (
                            <Suspense fallback={Loading}>
                                <Members />
                            </Suspense>
                        )
                    },
                    {
                        path: "requests",
                        element: (
                            <Suspense fallback={Loading}>
                                <Requests />
                            </Suspense>
                        )
                    },
                    {
                        path: "security",
                        element: (
                            <Suspense fallback={Loading}>
                                <Security />
                            </Suspense>
                        )
                    },
                    {
                        path: "branches",
                        element: (
                            <Suspense fallback={Loading}>
                                <Branches />
                            </Suspense>
                        )
                    },
                    {
                        path: "branches/:id",
                        element: (
                            <Suspense fallback={Loading}>
                                <BranchesDetails />
                            </Suspense>
                        )
                    }
                ]
            },
            {
                path: "all-tickets/open/:ticketId",
                element: <TicketLayout />,
                children: [
                    {
                        path: "generalInfo",
                        element: (
                            <Suspense fallback={Loading}>
                                <GeneralInformation />
                            </Suspense>
                        )
                    },
                    {
                        path: "organizationInfo",
                        element: (
                            <Suspense fallback={Loading}>
                                <OrganizationInfo />
                            </Suspense>
                        )
                    },
                    {
                        path: "package-details",
                        element: (
                            <Suspense fallback={Loading}>
                                <PackageDetails />
                            </Suspense>
                        )
                    },
                    {
                        path: "members-and-connections",
                        element: (
                            <Suspense fallback={Loading}>
                                <Members />
                            </Suspense>
                        )
                    },
                    {
                        path: "requests",
                        element: (
                            <Suspense fallback={Loading}>
                                <Requests />
                            </Suspense>
                        )
                    },
                    {
                        path: "security",
                        element: (
                            <Suspense fallback={Loading}>
                                <Security />
                            </Suspense>
                        )
                    },
                    {
                        path: "branches",
                        element: (
                            <Suspense fallback={Loading}>
                                <Branches />
                            </Suspense>
                        )
                    },
                    {
                        path: "branches/:id",
                        element: (
                            <Suspense fallback={Loading}>
                                <BranchesDetails />
                            </Suspense>
                        )
                    }
                ]
            },
            {
                path: "all-tickets",
                element: (
                    <Suspense fallback={Loading}>
                        <AllTicketsPage />
                    </Suspense>
                )
            },
            {
                path: "organizations",
                loader: managerLoader,
                element: (
                    <Suspense fallback={Loading}>
                        <OrganizationsPage />
                    </Suspense>
                )
            },
            {
                path: "organizations/branches",
                loader: managerLoader,
                element: (
                    <Suspense fallback={Loading}>
                        <BranchesPage />
                    </Suspense>
                )
            },
            {
                path: "clients",
                loader: managerLoader,
                element: (
                    <Suspense fallback={Loading}>
                        <ClientsPage />
                    </Suspense>
                )
            },
            {
                path: "notifications",
                element: (
                    <Suspense fallback={Loading}>
                        <NotificationsPage />
                    </Suspense>
                )
            },
            {
                path: "all-tickets/all-emails/:ticketId",
                element: (
                    <Suspense fallback={Loading}>
                        <AllEmailsPage />
                    </Suspense>
                )
            },
            {
                path: "new-tickets/all-emails/:ticketId",
                element: (
                    <Suspense fallback={Loading}>
                        <AllEmailsPage />
                    </Suspense>
                )
            },
        ]
    },
]);

export default router