import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom"
import Header from "../general/Header";
import SideMenu from "../general/SideMenu";
import classes from "./MainLayout.module.scss"
import BreadCrumb from "../ui/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import { getFirebaseToken, onMessageListener } from "../../util/firebase";
import { useLazyQuery, useMutation } from "@apollo/client";
import { UPDATE_FIREBASE_TOKEN } from "../../graphql/Mutations";
import { saveFirebaseToken } from "../../store/slices/authSlice";
import { COUNT_UNREAD_MESSAGES } from "../../graphql/Queries";
import { clearHavingOrganization, clearTicketInfo, saveNotificationNum } from "../../store/slices/ticketInfoSlice";

const MainLayout = () => {
    const location = useLocation().pathname;
    const dispatch = useDispatch()
    const { language, firebaseToken, user } = useSelector(state => state.auth)
    const [isOpen, setIsOpen] = useState(false);
    const [newFirebaseToken, setNewFirebaseToken] = useState(firebaseToken);
    const [notificationNum, setNotificationNum] = useState(0);

    getFirebaseToken(setNewFirebaseToken);

    const [updateFirebaseToken] = useMutation(UPDATE_FIREBASE_TOKEN, {
        onCompleted: () => {
            dispatch(saveFirebaseToken(newFirebaseToken))
        },
    })

    const [countUnreadMessages] = useLazyQuery(COUNT_UNREAD_MESSAGES, {
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
            dispatch(saveNotificationNum(data?.readUnreadMessagesCount?.data))
            setNotificationNum(data?.readUnreadMessagesCount?.data)
        }
    })

    useEffect(() => {
        // debugger
        if ((newFirebaseToken !== firebaseToken) && user) {
            updateFirebaseToken({
                variables: {
                    firebaseToken: newFirebaseToken,
                }
            })
            saveFirebaseToken(newFirebaseToken)
        }
    }, [newFirebaseToken, firebaseToken, saveFirebaseToken]);

    useEffect(() => {
        countUnreadMessages()
    }, [notificationNum])
    useEffect(() => {
        if (!location.includes('open')) {
            dispatch(clearTicketInfo());
            dispatch(clearHavingOrganization());


        }
    }, [location])

    onMessageListener().then(payload => {
        countUnreadMessages()
    }).catch(err => console.log('failed: ', err)).then(() => console.log('done'));


    return (
        <div className={classes.layout} dir={language === "ar" ? "rtl" : "ltr"}>
            <div className={classes.header}>
                <Header isOpen={isOpen} setIsOpen={setIsOpen} />
            </div>
            <div className={classes.main} style={{ paddingLeft: location?.includes('all-emails') && '150px' }}>
                <div className={classes.sideMenu} style={{ maxWidth: (location?.includes('open') || location?.includes('all-emails')) && "120px" }}>
                    <SideMenu isOpen={isOpen} setIsOpen={setIsOpen} />
                </div>

                <div className={classes.pages}>
                    <div className={classes.breadCrumb} style={{ paddingLeft: location?.includes('open') && '180px' }}>
                        <BreadCrumb />
                    </div>
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default MainLayout;