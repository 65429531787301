import classes from "./Header.module.scss"
import { Badge, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IoNotificationsOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import logo from "../../assets/images/logo.png"
import UserDropDown from "./UserDropDown";


const Header = () => {
    const navigate = useNavigate()
    const { notificationNum } = useSelector((state) => state.ticketInfo);

    return (
        <header className={classes.header}>
            <div className={classes.left}>
                <div className={classes.logo}>
                    <img src={logo} alt="logo" />
                    <p>AMS</p>
                </div>
                {/* <IoMenu onClick={() => setIsOpen(!isOpen)} className={classes.icon} /> */}
            </div>
            <div className={classes.right}>
                {/* <LanguageDropdown /> */}
                <Divider orientation="vertical" variant="middle" flexItem style={{ borderColor: "var(--grayColor800)" }} />
                {
                    notificationNum > 0 ?
                        <Badge badgeContent={notificationNum} color="primary" style={{ cursor: "pointer" }}>
                            <IoNotificationsOutline size={25} color="var(--grayColor800)" className={classes.notificationIcon} onClick={() => navigate("/notifications")} />
                        </Badge>
                        :
                        <IoNotificationsOutline size={25} color="var(--grayColor800)" className={classes.notificationIcon} onClick={() => navigate("/notifications")} />
                }

                <Divider orientation="vertical" variant="middle" flexItem style={{ borderColor: "var(--grayColor800)" }} />
                <div className={classes.userInfo}>
                    <UserDropDown />
                </div>
            </div>
        </header>
    )
}

export default Header