import { useParams } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom"
import words from "../../assets/words"
import PageHeader from "../general/PageHeader"
import Button from "../ui/Button"
import OutlineButton from "../ui/OutlineButton"
import SuccessMessage from "../ui/SuccessMessage"
import classes from "./TicketLayout.module.scss"
import TicketSidemenu from "../general/TicketSidemenu"
import TicketInfo from "../ui/TicketInfo"
import TransferTicket from "../main/ticketDetails/TransferTicket"
import withModal from "../ui/withModal"
import ConfirmModal from "../common/ConfirmModal"
import { CLOSE_TICKET } from "../../graphql/Mutations";
import { READ_TICKET_DETAILS } from "../../graphql/Queries";
import { saveTicketInfo } from "../../store/slices/ticketInfoSlice";


const TicketLayout = () => {
    const { language } = useSelector(state => state.auth)
    const { personalInfoChanged } = useSelector(state => state.ticketInfo)
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const location = useLocation().pathname;

    const { ticketId } = useParams()
    const [ticketInfo, setTicketInfo] = useState({})
    const [showTransferTicketModal, setShowTransferTicketModal] = useState(false);
    const [showCloseTicketModal, setShowCloseTicketModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [closeErrorMsg, setCloseErrorMsg] = useState('');
    const [getTicketInfo] = useLazyQuery(READ_TICKET_DETAILS, {
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
            if (data?.readTicketDetails?.success) {
                setTicketInfo(data?.readTicketDetails?.data)
                let countryToApi = { countryApi: data?.readTicketDetails?.data?.country?.slice(0, 2).toUpperCase() }
                const arr = { ...data?.readTicketDetails?.data, countryToApi }
                dispatch(saveTicketInfo(arr))
            }
        }
    });

    useEffect(() => {
        if (ticketId) {
            getTicketInfo({ variables: { ticketId: ticketId } })
        }
    }, [ticketId,personalInfoChanged])


    const [closeTicket, { loading: closeLoading }] = useMutation(CLOSE_TICKET, {
        onCompleted: (data) => {
            if (data.closeTicket.success) {
                setShowCloseTicketModal(false)
                setShowSuccessModal(true)
                navigate("/new-tickets")
            } else {
                setCloseErrorMsg(data.closeTicket.message)
            }
        }
    }
    )


    const onCloseTicket = () => {
        closeTicket({ variables: { ticketId: ticketId } })
    }
    const navigateToAllEmails = () => {
        if (location.includes('all-tickets')) {
            navigate("/all-tickets/all-emails/" + ticketId)

        } else if (location.includes('new-tickets')) {
            navigate("/new-tickets/all-emails/" + ticketId)

        }
    }
    const TransferTicketModal = useMemo(() => withModal(TransferTicket), [])
    const ConfirmCloseTicketModal = useMemo(() => withModal(ConfirmModal), [])
    const SuccessModal = useMemo(() => withModal(SuccessMessage), [])
    return (
        <div className={classes.container}>
            <SuccessModal
                show={showSuccessModal}
                onHide={() => setShowSuccessModal(false)}
                onDone={() => setShowSuccessModal(false)}
            />
            <ConfirmCloseTicketModal
                title={words.CLOSE_TICKET[language]}
                show={showCloseTicketModal}
                confirmData={ticketInfo.customerId ? { name: ticketInfo.customerId?.firstName + ' ' + ticketInfo?.customerId?.lastName, aId: ticketInfo.aId ? ticketInfo.aId : '' } :
                    { name: ticketInfo.employeeId?.firstName + ' ' + ticketInfo?.employeeId?.lastName, aId: ticketInfo.aId ? ticketInfo.aId : '' }
                }
                ticketNumber={ticketInfo.ticketNumber}
                confirmTitle={words.ARE_YOU_SURE_YOU_WANT_TO_CLOSE_THIS_TICKET[language]}
                onHide={() => setShowCloseTicketModal(false)}
                onNoClicked={() => setShowCloseTicketModal(false)}
                onYesClicked={() => onCloseTicket()}
                loading={closeLoading}
                errorMsg={closeErrorMsg}
            />

            <TransferTicketModal
                title={words.TRANSFER_TICKET[language]}
                show={showTransferTicketModal}
                ticketInfo={ticketInfo}
                ticketId={ticketId}
                handleYes={() => {
                    setShowTransferTicketModal(false);
                    setShowSuccessModal(true);
                    navigate("/new-tickets")
                }}
                onHide={() => setShowTransferTicketModal(false)}
            />

            <PageHeader pageTitle={`Ticket (#${ticketInfo.ticketNumber ? ticketInfo.ticketNumber : ''}) `} btnsStyle={{ width: "600px" }}>
                <OutlineButton onClick={navigateToAllEmails}>{words.ALL_EMAILS[language]}</OutlineButton>
                <Button onClick={() => setShowTransferTicketModal(true)}>{words.TRANSFER_TICKET[language]}</Button>
                <Button
                    onClick={() => setShowCloseTicketModal(true)}
                    style={{
                        backgroundColor: "var(--primaryColor500)",
                        borderColor: "var(--primaryColor500)",
                        fontSize: "18px", fontWeight: "500"
                    }}
                >
                    {words.CLOSE_TICKET[language]}
                </Button>
            </PageHeader>

            <div className={classes.mainPage}>
                <div className={classes.sideMenu}>
                    <TicketSidemenu id={ticketInfo?.customerId}/>
                </div>
                <div className={classes.mainData}>
                    <div>
                        <TicketInfo ticketInfo={ticketInfo} style={{ backgroundColor: "var(--whiteColor)" }} />
                    </div>
                    <div className={classes.pagesInfo}>
                        {ticketInfo?.customerId && <Outlet />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TicketLayout