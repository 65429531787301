import SearchBox from "../ui/SearchBox"
import classes from "./PageHeader.module.scss"
import { useSelector } from "react-redux"
import words from "../../assets/words"
import { RiSoundModuleLine } from "react-icons/ri"
import Button from "../ui/Button"
import { fontGrid } from "@mui/material/styles/cssUtils"

const PageHeader = ({ pageTitle, onSearch, onFilter, searchValue, btnsStyle, children,containerStyle,titleStyle,searchInputType,searhPlaceholder}) => {
    const { language } = useSelector(state => state.auth)

    return (
        <div className={classes.header} style={containerStyle}>
            <p className={classes.pageTitle} style={titleStyle}>{pageTitle}</p>
            <div className={classes.actionBtns}>
                {
                    onSearch &&
                    <SearchBox
                        searchInput={searchValue}
                        onSearchInputChange={onSearch}
                        onSearchClick={onSearch}
                        searchInputType={searchInputType}
                        searhPlaceholder={searhPlaceholder}
                    />
                }

                <div className={classes.btns} style={btnsStyle}>
                    {
                        onFilter &&
                        <Button
                            style={{
                                padding: "0.5rem 0.7rem",
                                backgroundColor: "var(--primaryColor500)",
                                border: "2px solid var(--primaryColor500)",
                                color: "#fff",
                                fontSize:'16px'
                            }}
                            onClick={onFilter}
                        >
                            <RiSoundModuleLine />
                            {words.FILTER[language]}
                        </Button>
                    }
                    {children}
                </div>

            </div>
        </div>
    )
}

export default PageHeader