import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { BsSearch } from "react-icons/bs";
import { BiRightArrowAlt, BiLeftArrowAlt } from 'react-icons/bi';
import classes from "./SearchBox.module.scss";
import words from "../../assets/words";

const SearchBox = ({ searchInput, onSearchInputChange, onSearchClick ,searchInputType='text',searhPlaceholder}) => {
    const language = useSelector((state) => state.auth.language);

    const [isHovered, setIsHovered] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const showSearchInput = isHovered || isFocused;

    useEffect(() => {
        if (searchInput) {
            setIsHovered(true);
            setIsFocused(true);
        }
    }, [showSearchInput, searchInput]);

    const onKeyPress = (e) => {
        if (e.which === 13) {
            onSearchClick(e.target.value)
        }
    }

    return (
        <div className={classes.container}
            style={showSearchInput || searchInput || isFocused || isHovered ? { width: "100%" } : undefined}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => {
                if (searchInput) {
                    setIsHovered(true)
                }
                else {
                    setIsHovered(false)
                }
            }}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            hover={showSearchInput ? "true" : undefined}
        >

            <div className={classes.searchContainer} style={showSearchInput || searchInput || isFocused || isHovered ? { display: "flex" } : { display: "none" }}>
                <input type={searchInputType} placeholder={searhPlaceholder?searhPlaceholder:words.SEARCH[language]} className={`${classes.searchInput} number-input`} style={showSearchInput || searchInput || isFocused || isHovered ? { display: "flex" } : { display: "none" }} value={searchInput} onChange={(e) => onSearchInputChange(e.target.value)} onKeyPress={(e) => onKeyPress(e)} />
            </div>

            {showSearchInput || searchInput ?
                <div className={classes.search}>
                </div>
                :
                <div className={classes.glass}>
                    <BsSearch />{" "}
                </div>
            }

        </div>
    )
}

export default SearchBox