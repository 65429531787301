import { gql } from "@apollo/client";

export const LOGIN = gql`
mutation LoginCSUser($email: String!, $password: String!) {
  loginCSUser(email: $email, password: $password) {
    code
    success
    message
    data {
      _id
      firstName
      lastName
      phoneNumber
      email
      profilePictureUrl
      role
      dateOfHiring
      createdAt
      updatedAt
    }
    token
  }
}
`
export const LOGOUT = gql`
mutation LogoutCSUser {
  logoutCSUser {
    code
    success
    message
  }
}
`
export const TRANSFER_TICKET = gql`
mutation TransferTicket($ticketId: ID!, $comment: String!, $employeeId: ID!) {
  transferTicket(ticketId: $ticketId, comment: $comment, employeeId: $employeeId) {
    code
    success
    message
  }
}
`
export const CLOSE_TICKET = gql`
mutation CloseTicket($ticketId: ID!) {
  closeTicket(ticketId: $ticketId) {
    code
    message
    success
  }
}
`

export const CREATE_TICKET = gql`
mutation CreateTicket($country: Country!, $source: Source!, $platform: Platform!, $comment: String!, $sendNotifaction: Boolean!, $customerId: String!, $ticketReason: ticketReason!, $email: String!) {
  createTicket(country: $country, source: $source, platform: $platform, comment: $comment, sendNotifaction: $sendNotifaction, customerId: $customerId, ticketReason: $ticketReason, email: $email) {
    code
    success
    message
    data {
      _id
      country
      comment
      sendNotifaction
      customerId {
        _id
        firstName
        lastName
        phoneNumber
        email
        profilePictureUrl
      }
      
      ticketNumber
      status
      isOpened
      email
      createdBy
      createdAt
      updatedAt
      platform {
        key
        value
      }
      source {
        key
        value
      }
      ticketReason {
        key
        value
      }
    }
  }
}
`
export const ASSIGN_TO_ME = gql`
mutation CreateTicketAssignToEmployee($country: Country!, $source: Source!, $platform: Platform!, $comment: String!, $ticketReason: ticketReason!, $email: String!, $subject: String!, $body: String!, $employeeId: String!, $attachments: [Upload]) {
  createTicketAssignToEmployee(country: $country, source: $source, platform: $platform, comment: $comment, ticketReason: $ticketReason, email: $email, subject: $subject, body: $body, employeeId: $employeeId, attachments: $attachments) {
    code
    success
    message
    data {
      _id
      country
      source {
        key
        value
      }
      platform {
        key
        value
      }
      comment
      sendNotifaction
      customerId {
        _id
        firstName
        lastName
        phoneNumber
        countryCode
        email
        profilePictureUrl
        aId
      }
      ticketReason {
        value
        key
      }
      ticketNumber
      agentId {
        _id
        firstName
        lastName
        phoneNumber
        email
        profilePictureUrl
        role
        dateOfHiring
        createdAt
        updatedAt
      }
      status
      isOpened
      email
      createdBy
      TransferReason
      createdAt
      updatedAt
    }
  }
}
`
export const UPDATE_CUSTOMER_INFORMATION=gql`
mutation UpdateCustomerInformation($ticketId: ID!,$input: UpdateCustomerInput!, $country: Country!, $userId: ID!) {
  updateCustomerInformation(ticketId: $ticketId,input: $input, country: $country, userId: $userId) {
    code
    message
    success
  }
}`
export const UPDATE_MEMBER=gql`
mutation UpdateMember($ticketId: ID!,$input: UpdateCustomerInput!, $country: Country!, $userId: ID!, $memberId: ID!) {
  updateMember(ticketId: $ticketId,input: $input, country: $country, userId: $userId, memberId: $memberId) {
    code
    message
    success
  }
}`

export const DELETE_MEMBER=gql`
mutation DeleteMember($ticketId: ID!,$country: Country!, $userId: ID!, $memberId: ID!) {
  deleteMember(ticketId: $ticketId,country: $country, userId: $userId, memberId: $memberId) {
    code
    success
    message
  }
}
`
export const DELETE_CONNECTION=gql`
mutation DeleteConnection($ticketId: ID!,$country: Country!, $userId: ID!, $connectionId: ID!) {
  deleteConnection(ticketId: $ticketId,country: $country, userId: $userId, connectionId: $connectionId) {
    code
    message
    success
  }
}`
export const FORGET_PASSWORD=gql`
mutation SendCSOTP($ticketId: ID!,$country: String!, $phoneNumber: String!, $countryCode: String!) {
  sendCSOTP(ticketId: $ticketId,country: $country, phoneNumber: $phoneNumber, countryCode: $countryCode) {
    code
    success
    message
  }
}
`
export const CHANGE_PASSWORD=gql`
mutation ChangeSubUserPassword($ticketId: ID!,$country: Country!, $userId: ID!, $memberId: ID!) {
  changeSubUserPassword(ticketId: $ticketId,country: $country, userId: $userId, memberId: $memberId) {
    code
    success
    message
  }
}
`


export const ADD_NEW_MEMBER=gql`
mutation AddMemberToCustomer($ticketId: ID!,$country: Country!, $userId: ID!, $memberId: ID!) {
  addMemberToCustomer(ticketId: $ticketId,country: $country, userId: $userId, memberId: $memberId) {
    code
    success
    message
  }
}`

export const UNDO_REQUEST=gql`
mutation UndoRequestMessages($ticketId: ID!,$country: Country!, $userId: ID!, $messageId: ID!) {
  undoRequestMessages(ticketId: $ticketId,country: $country, userId: $userId, messageId: $messageId) {
    code
    message
    success
  }
}
`

export const COMPOSE_EMAIL = gql`
mutation ComposeEmail($ticketNumber: Int!,$ticketId: ID!, $mailContentInHTML: String!, $sendTo: [String]!, $subject: String!, $attachments: [Upload!]) {
  composeEmail(ticketId: $ticketId,ticketNumber: $ticketNumber, mailContentInHTML: $mailContentInHTML, sendTo: $sendTo, subject: $subject, attachments: $attachments) {
    code
    success
    message
  }
}
`

export const REPLY_EMAIL = gql`
mutation SendReplyEmail($ticketId: ID! $messageId: String!, $mailContentInHTML: String!, $sendTo: [String]!, $subject: String!, $attachments: [Upload!], $body: String!) {
  sendReplyEmail(ticketId: $ticketId, messageId: $messageId, mailContentInHTML: $mailContentInHTML, sendTo: $sendTo, subject: $subject, attachments: $attachments, body: $body) {
    code
    success
    message
  }
}
`

export const READ_TICKET_LOGS=gql`
query ReadTicketLogs($ticketId: ID!) {
  readTicketLogs(ticketId: $ticketId) {
    code
    success
    message
    data {
      _id
      status
      action
      createdAt
      userId {
        firstName
        lastName
      }
    }
  }
}`

export const UPDATE_FIREBASE_TOKEN = gql`
mutation UpdateCSFirebaseToken($firebaseToken: String!) {
  updateCSFirebaseToken(firebaseToken: $firebaseToken) {
    code
    success
    message
  }
}
`
export const UPDATE_BRANCH = gql`
mutation UpdateBranchData($country: CountryOfResidence!, $branchId: ID!, $branchName: String!, $email: String!, $phoneNumber: String!, $countryCode: String!, $educationType: EducationType!, $countryOfResidence: CountryOfResidence!, $governorate: String!, $city: String!, $street: String, $area: String) {
  updateBranchData(country: $country, branchId: $branchId, branchName: $branchName, email: $email, phoneNumber: $phoneNumber, countryCode: $countryCode, educationType: $educationType, countryOfResidence: $countryOfResidence, governorate: $governorate, city: $city, street: $street, area: $area) {
    code
    success
    message
  }
}
`
export const UPDATE_CS_SUBSCRIPTION_STATUS = gql`
mutation UpdateCSSubscriptionStatus($country: Country!, $providerId: ID!, $isActive: Boolean!) {
  updateCSSubscriptionStatus(country: $country, providerId: $providerId, isActive: $isActive) {
    code
    success
    message
  }
}
`
export const UPDAE_ORGANIZATION_NAME=gql`
mutation UpdateOrganization($providerId: ID!, $name: String!) {
  updateOrganization(providerId: $providerId, name: $name) {
    code
    success
    message
  }
}
`

export const SEND_OTP=gql`
mutation SendCSPackageOTP($country: String!, $userId: ID, $providerId: ID, $isCsManager: Boolean!) {
  sendCSPackageOTP(country: $country, userId: $userId, providerId: $providerId, isCSManager: $isCsManager) {
    code
    success
    message
  }
}`
export const CHECK_OTP=gql`
mutation CheckCSOTP($country: String!, $userId: ID, $otp: String!,$providerId: ID,$isCsManager: Boolean!) {
  checkCSOTP(country: $country, userId: $userId, OTP: $otp, providerId: $providerId, isCSManager: $isCsManager) {
    code
    success
    message
  }
}`

export const UPGRADE_SUBSCRIPTION_PLANE=gql`
mutation UpdateCSSubscriptionPlan($country: Country!, $providerId: ID!, $packageId: String!) {
  updateCSSubscriptionPlan(country: $country, providerId: $providerId, packageId: $packageId) {
    code
    success
    message
  }
}`

export const GENERATE_WORK_LIST_TOKEN=gql`
mutation GenerateWorklistToken {
  generateWorklistToken {
    code
    success
    message
    data
  }
}`