import classes from "./SideMenu.module.scss"
import { LuPieChart } from "react-icons/lu";
import { HiOutlineTicket } from "react-icons/hi2";
import { FiBarChart } from "react-icons/fi";
import { RiNewspaperLine } from "react-icons/ri";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { PiUsersThree } from "react-icons/pi";
import words from "../../assets/words";
import { useSelector } from "react-redux";

const SideMenu = ({ isOpen, setIsOpen }) => {
    const location = useLocation().pathname;
    const { language, role } = useSelector(state => state.auth)
    const navigate = useNavigate()
    const [currentTab, setCurrentTab] = useState(location)
    const [currentHref, setCurrentHref] = useState(location)
    const [tabs, setTabs] = useState([])


    const managerTabs = [
        {
            name: words.DASHBOARD[language],
            title: words.AMS[language],
            icon: <LuPieChart />,
            href: "/"
        },
        {
            name: words.NEW_TICKETS[language],
            title: words.TICKETS[language],
            icon: <HiOutlineTicket />,
            href: "/new-tickets"
        },
        {
            name: words.ALL_TICKETS[language],
            title: "",
            icon: <RiNewspaperLine />,
            href: "/all-tickets"
        },
        {
            name: words.ORGANIZATIONS[language],
            title: words.ORGANIZATION[language],
            icon: <FiBarChart />,
            href: "/organizations"
        },
        {
            name: words.CLIENTS[language],
            title: "",
            icon: <PiUsersThree />,
            href: "/clients"
        },
    ]

    const AgentTabs = [
        {
            name: words.NEW_TICKETS[language],
            title: words.TICKETS[language],
            icon: <HiOutlineTicket />,
            href: "/new-tickets"
        },
        {
            name: words.ALL_TICKETS[language],
            title: "",
            icon: <RiNewspaperLine />,
            href: "/all-tickets"
        },
    ]

    useEffect(() => {
        if (location.includes(currentHref)) {
            setCurrentTab(currentHref)

        } else {
            setCurrentTab(location)

        }

    }, [location])

    useEffect(() => {
        if (role === "MANAGER") {
            setTabs(managerTabs)
        } else {
            setTabs(AgentTabs)
        }
    },[role, language])

    return (
        <div className={isOpen ? classes.sideMenu : classes.closedsideMenu}>
            <button className={classes.close} onClick={() => setIsOpen(false)}>
                <MdClose />
            </button>
            {
                tabs.map((tab, index) => {
                    return (
                        <div key={index}>
                            {tab.title && <small>{tab.title}</small>}
                            <p className={tab.href === currentTab ? classes.active : classes.unActive}
                                style={{ justifyContent: (location?.includes('open') || location?.includes('all-emails')) && 'center', padding: (location?.includes('open') || location?.includes('all-emails') )&& '1rem' }}
                                onClick={() => {
                                    setCurrentHref(tab.href)
                                    navigate(tab.href)
                                }}>
                                {tab.icon} {(!location?.includes('open') && !location?.includes('all-emails')) && <span>{tab.name}</span>}
                            </p>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default SideMenu