import { useSelector } from 'react-redux';
import Spinner from "react-bootstrap/Spinner";
import classes from './ConfirmModal.module.scss'
import Button from '../ui/Button';
import words from '../../assets/words';

const ConfirmModal = ({ loading, confirmData, confirmTitle, ticketNumber, hasSubTitle = false, image, onYesClicked, onNoClicked, errorMsg, confirmTitleStyle, note }) => {
    const { language, } = useSelector((state) => state.auth);
    return (
        <div className={classes.container}>
            {image && <img src={image} alt="warning" />}
            {
                confirmData &&
                <div className={classes.confirmData}>
                    <div className={classes.name}>{confirmData ? confirmData.name : ''}</div>
                    <div className={classes.aId}>{confirmData?.aId}</div>
                    {ticketNumber && <div className={classes.ticketNumber}>Ticket(#<span>{ticketNumber}</span>)</div>}
                </div>
            }
            {hasSubTitle && <div className={classes.actionType}>{confirmData?.actionType}</div>}

            <div className={classes.confirm} style={confirmTitleStyle}>{confirmTitle}</div>
            {note && <div className={classes.noteContainer}>

                <div className={classes.note}>{words.NOTE[language] + " :  "}</div>
                <div className={classes.noteMessage}>{note}</div>


            </div>}
            <div className={classes.btnsContainer}>
                <div className={classes.yesBtn}>
                    <button onClick={onYesClicked} disabled={loading} style={{display:'flex',justifyContent:'center'}}>
                        {loading ? <Spinner as="span" animation="border" size="sm" />
                            : words.YES[language]}
                    </button>

                </div>
                <div className={classes.btn}>
                    <Button onClick={onNoClicked} disabled={loading} style={{ width: "89%", padding: "8px .3rem", borderRadius: "10px" }}>{words.NO[language]}</Button>
                </div>

            </div>
            {errorMsg && <p className={classes.error}>{errorMsg}</p>}


        </div>
    )

}


export default ConfirmModal