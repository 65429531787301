import { useSelector } from 'react-redux';
import classes from './TransferTicket.module.scss'
import Textarea from '../../ui/Textarea';
import words from '../../../assets/words';
import SelectInput from './../../ui/SelectInput';
import Button from '../../ui/Button';
import { useLazyQuery, useMutation } from '@apollo/client';
import { READ_ALL_CS_USERS } from '../../../graphql/Queries';
import { useEffect, useState } from 'react';
import { TRANSFER_TICKET } from '../../../graphql/Mutations';


const TransferTicket = ({ ticketInfo,ticketId,handleYes }) => {
    const { language } = useSelector((state) => state.auth);
    const [employeesOptions, setEmployeesOptions] = useState([])
    const [selectedEmployee, setSelectedEmployee] = useState(null)
    const [comment, setComment] = useState(null)

    const [errors, setErrors] = useState({
        selectEmployeeError: null,
        disabled: true
    })
    const [getEmployees] = useLazyQuery(READ_ALL_CS_USERS, {
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
            if (data?.readAllCSUsers?.success) {
                const employeeOptions = []
                data?.readAllCSUsers?.data?.map((item) => {
                    employeeOptions.push({ label: item.firstName + ' ' + item.lastName, value: item._id })
                })
                setEmployeesOptions(employeeOptions);
            } else {
                setErrors({ ...errors, selectEmployeeError: data?.readAllCSUsers.message })
            }
        }
    });
    useEffect(() => {
        getEmployees()
    }, [])
    const [transferTicket, { loading: transferLoading }] = useMutation(TRANSFER_TICKET, {
        onCompleted: (data) => {
            if (data.transferTicket.success) {
                handleYes();
            }
        }
    }
    )
    const onTransferTicket = () => {
        transferTicket({ variables: { ticketId: ticketId, employeeId: selectedEmployee.value, comment: comment } })
    }
    return (
        <div className={classes.container}>

            <div className={classes.ticketInfo}>
                <div className={classes.name}>{ticketInfo?.customerId?ticketInfo?.customerId?.firstName+' '+ticketInfo?.customerId?.lastName:ticketInfo?.employeeId?.firstName+' '+ticketInfo?.employeeId?.lastName}</div>
                <div className={classes.aId}>{ticketInfo.aId}</div>
                <div className={classes.ticketNumber}>{words.TICKET[language]} <span> {'(#' + ticketInfo.ticketNumber + ')'} </span></div>

            </div>
            <div className={classes.select}>
                <SelectInput
                    onChange={(obj)=>setSelectedEmployee(obj)}
                    value={selectedEmployee}
                    options={employeesOptions} required={true}
                    placeholder={words.SELECT_EMPLOYEE[language]}
                />
            </div>
            <div className={classes.comment}>
                <Textarea
                    required={true}
                    rows={4}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder={words.ENTER_COMMENT[language]}
                    style={{ fontSize: '18px' }}
                />

            </div>

            <div className={classes.btn}>
                <Button onClick={onTransferTicket} loading={transferLoading} disabled={!comment || !selectedEmployee || transferLoading}>{words.TRANSFER[language]}</Button>
            </div>

        </div>
    )
}

export default TransferTicket