import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        language: sessionStorage.getItem('language') || 'en',
        country: sessionStorage.getItem("country") || null,
        ticketCountry: null,
        token: sessionStorage.getItem('token') || null,
        firebaseToken: sessionStorage.getItem('firebaseToken') || null,
        user: JSON.parse(sessionStorage.getItem('user')) || null,
        organization: JSON.parse(sessionStorage.getItem('organization')) || null,
        generalInfo: JSON.parse(sessionStorage.getItem('generalInfo')) || null,
        role: sessionStorage.getItem('role') || null,
    },
    reducers: {
        saveCountry: (state, action) => {
            state.country = action.payload;
            sessionStorage.setItem("country", action.payload);
        },
        saveTicketCountry: (state, action) => {
            state.ticketCountry = action.payload;
        },
        saveLanguage: (state, action) => {
            if (state.language !== action.payload.language) {
                state.language = action.payload.language;
                sessionStorage.setItem('language', action.payload.language);
            }
        },
        saveToken: (state, action) => {
            state.token = action.payload;
            sessionStorage.setItem('token', action.payload);
        },
        saveFirebaseToken: (state, action) => {
            state.firebaseToken = action.payload;
            sessionStorage.setItem('firebaseToken', action.payload);
        },
        login: (state, action) => {
            const userData = {
                id: action.payload.user._id,
                firstName: action.payload.user.firstName,
                lastName: action.payload.user.lastName,
                profilePictureUrl: action.payload.user.profilePictureUrl,
            }
            state.user = userData;
            const userJson = JSON.stringify(userData);
            sessionStorage.setItem('user', userJson);

            state.token = action.payload.token;
            sessionStorage.setItem('token', action.payload.token);

            state.role = action.payload.user.role;
            sessionStorage.setItem('role', action.payload.user.role);
        },
        logout: (state) => {
            state.country = null;
            state.user = null;
            state.token = null;
            state.firebaseToken = null;
            state.organization = null;
            sessionStorage.clear()
        },
    }
})

export const authReducer = authSlice.reducer;
export const { logout, login, saveToken, saveFirebaseToken, saveLanguage, saveTicketCountry } = authSlice.actions;