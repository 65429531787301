import { RouterProvider } from "react-router-dom";
import { ApolloClient, ApolloProvider, InMemoryCache, from, split } from "@apollo/client";
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "./store";
import { ApolloLink } from "@apollo/client/core";
import router from "./router";


const getCountryLinks = country => {
  // debugger
  let links = {
    database:
      'https://ayamedica-cs-api-staging-b9027fdec1bd.herokuapp.com/graphql',
    monolith: '//ayamedica-cs-api-staging-b9027fdec1bd.herokuapp.com/graphql'
  }
  return links
  // if (country === 'EG') {
  //   return links
  // } else if (country === 'SA') {
  //   links = {
  //     database:
  //       'https://current--ayamedica-supergraph-r8r9a5.apollographos.net/graphql',
  //     monolith: '//monolith-subgraph-development.herokuapp.com/graphql'
  //   }
  //   return links
  // }
}

const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:'

const wsLink = (language, link) =>
  new GraphQLWsLink(
    createClient({
      url: protocol + link?.monolith,
      options: {
        reconnect: true
      },
      connectionParams: {
        Authorization: sessionStorage.getItem('token'),
        language: language
      }
    })
  )

const httpLink = link =>
  createUploadLink({
    uri: link?.database
    // uri: 'https://current--ayamedica-supergraph-r8r9a5.apollographos.net/graphql',
    // uri: 'http://192.168.1.20:4003/graphql',
  })

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = (language, country) =>
  split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink(language, getCountryLinks(country)),
    httpLink(getCountryLinks(country))
  )

const authLink = (language, token) =>
  setContext((_, { headers }) => {
    // const token = sessionStorage.getItem('token')
    return {
      headers: {
        authorization: (token && token !== "null") ? token : '',
        language: language,
        ...headers,
        'keep-alive': 'true',
        'content-type': 'application/json'
      }
    }
  })

const successLink = new ApolloLink((operation, forward) => {
  // Use the `forward` function to pass the operation down the middleware chain.
  return forward(operation).map((response) => {
    // This function is called when a response is received.
    const operationName = Object.keys(response.data)
    operationName.forEach((name) => {
      const code = response.data[name]?.code
      if (code === 450) {
        sessionStorage.clear()
        const redirectURL = 'https://cs.ayamedica.com/'
        // const redirectURL = "http://localhost:3002"
        window.location.href = redirectURL
      }
    })

    // Return the response to continue the chain.
    return response;
  });
});


const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      if (message === 'Please login to continue' || extensions?.helperMessage === "Please login to continue") {
        const redirectURL = 'https://cs.ayamedica.com/'
        window.location.href = redirectURL
      }
    })
  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const createApolloClient = (language, token, country) => {
  // debugger
  return new ApolloClient({
    link: from([
      errorLink,
      successLink,
      authLink(language, token).concat(splitLink(language, country))
    ]),
    cache: new InMemoryCache({
      dataIdFromObject: o => (o.id ? `${o.__typename}-${o.id}` : null)
    }),
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all',
        returnPartialData: true
      },
      query: {
        errorPolicy: 'all',
        returnPartialData: true
      }
    }
  })
}

function App() {
  const language = useSelector((state) => state.auth.language);
  const country = useSelector((state) => state.auth.country);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  if (sessionStorage.getItem("country") === null || sessionStorage.getItem("country") === "undefined") {
    // debugger
    dispatch(
      authActions.saveCountry(
        document.location.search?.split("country=")[1]?.split("?")[0]
      )
    );
  }
  const client = createApolloClient(
    language,
    token,
    country
      ? country
      : document.location.search?.split("country=")[1]?.split("?")[0]
  );



  return <ApolloProvider client={client}>
    <RouterProvider router={router} />
  </ApolloProvider>
}

export default App;
