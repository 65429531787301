import { gql } from "@apollo/client";

export const READ_COUNTRIES = gql`
query ReadAyamedicaCountries {
  readAyamedicaCountries {
    code
    success
    message
    data {
      key
      value
    }
  }
}`
export const READ_USER_DATA = gql`
query ReadUserData(
  $aId: String
  $email: String
  $nationalId: String
  $countryCode: String
  $phoneNumber: String
  $country: Country!
) {
  readUserData(
    aId: $aId
    email: $email
    nationalId: $nationalId
    countryCode: $countryCode
    phoneNumber: $phoneNumber
    country: $country
  ) {
    code
    success
    message
    data {
      _id
      aId
      firstName
      lastName
      email
      countryCode
      phoneNumber
      dateOfBirth
      gender {
        key
        value
      }
      nationalId
      passportId
      countryOfCitizenship {
        logo
        value
      }
      address {
        country {
          key
          value
        }
        governorate {
          key
          value
        }
      }
      roles {
        role
        providerData {
          _id
          aId
          name
          email
          countryCode
          phoneNumber
          logoUrl
        }
       
      }
      
    }
  }
}

`;

export const READ_TICKET_REASON = gql`
query ReadTicketReason {
  readTicketReason {
    code
    success
    message
    data {
      id
      key
      value
    }
  }
}`;
export const READ_ORGANIZATION_DETAILS = gql`
query ReadOrganizationDetails($country: Country!, $providerId: ID!) {
  readOrganizationDetails(country: $country, providerId: $providerId) {
    code
    success
    message
    data {
      _id
      name
      email
      providerId {
        _id
        name
        country
      }
      aId
      isMainBranch
      branchType
      educationType {
        key
        value
      }
      logoUrl
      countryCode
      phoneNumber
      createdAt
      address {
        _id
        country {
          key
          value
        }
        governorate {
          key
          value
        }
        city {
          key
          value
        }
        area
        street
        postalCode
      }
      studentsCount
      fGuardianCount
      sGuardianCount
      studentsWithoutGuardian
      gradesCount
      classesCount
      membersCount
    }
  }
}`;
export const READ_TICKET_STATS = gql`
query ReadPlatform {
  readPlatform {
    code
    success
    message
    data {
      id
      key
      value
    }
  }
  readSource {
    code
    success
    message
    data {
      id
      key
      value
    }
  }
}`;

export const READ_SOURCES = gql`
query ReadSource {
  readSource {
    code
    success
    message
    data {
      key
      value
    }
  }
}
`;
export const READ_EMPLOYEE_PERFORMANCE = gql`
query ReadEmployeesPerformance($startDate: Date!, $endDate: Date) {
  readEmployeesPerformance(startDate: $startDate, endDate: $endDate) {
    code
    success
    message
    data {
      _id
      agentName
      profilePictureUrl
      numberOfTicketsClosed
      performanceRating
    }
  }
}
`;
export const READ_USER_LOGS = gql`
query ReadUserLogs($userId: ID!, $startDate: Date!, $endDate: Date) {
  readUserLogs(userId: $userId, startDate: $startDate, endDate: $endDate) {
    code
    success
    message
    data {
      _id
      userId {
        firstName
        lastName
        profilePictureUrl
      }
      operationType
      operationName
      ticketId {
        ticketNumber
      }
      status
      action
      createdAt
    }
  }
}
`;
export const READ_ALL_BRANCHES = gql`
query ReadAllBranches($viewLimit: Int!, $page: Int!, $country: Country!, $providerId: ID) {
  readAllBranches(viewLimit: $viewLimit, page: $page, country: $country, providerId: $providerId) {
    code
    success
    message
    data {
      list {
        _id
        name
        email
        providerId {
          _id
          aId
          name
          email
          countryCode
          phoneNumber
          logoUrl
          country
          branchesCount
          totalStudents
          createdAt
        }
        aId
        isMainBranch
        branchType
        logoUrl
        countryCode
        phoneNumber
        createdAt
        studentsCount
        fGuardianCount
        sGuardianCount
        studentsWithoutGuardian
        gradesCount
        classesCount
        membersCount
     
        address {
          street
          city {
            key
            value
          }
          country {
            key
            value
          }
          governorate {
            key
            value
          }
          area
        }
        educationType {
          value
          key
        }
      }
      pagination {
        totalDocuments
        viewLimit
      }
    }
  }
}
`;

export const READ_ALL_NEW_TICKETS = gql`
query ReadAllNewTickets($page: Int!, $country: Country, $platform: Platform, $searchInput: String, $startDate: Date, $endDate: Date, $source: Source, $limit: Int!) {
  readAllNewTickets(page: $page, country: $country, platform: $platform, searchInput: $searchInput, startDate: $startDate, endDate: $endDate, source: $source, limit: $limit) {
    code
    success
    message
    data {
      list {
        _id
        ticketNumber
        customerId {
          _id
          firstName
          lastName
          phoneNumber
          countryCode
          aId
        }
        country
        source {
          value
        }
        platform {
          value
        }
        createdAt
        createdBy
        isOpened
        employeeId {
          firstName
          lastName
          phoneNumber
          countryCode
        }
      }
      pagination {
        totalDocuments
        viewLimit
      }
    }
  }
}`;

export const READ_ALL_CS_USERS = gql`
query ReadAllCSUsers {
  readAllCSUsers {
    code
    success
    message
    data {
      profilePictureUrl
      role
      dateOfHiring
      createdAt
      updatedAt
      firstName
      lastName
      phoneNumber
      email
      _id
    }
  }
}
`
export const READ_ALL_PLANS = gql`
query ReadSchoolPlans($providerId: ID, $country: CountryOfResidence) {
  readSchoolPlans(providerId: $providerId, country: $country) {
    _id
    package
    providerId
    perks
    currency
    price
    maxStudents
    maxBranches
  }
}
`
export const PACKAGE_DETAILS = gql`
query ReadCSPackageDetails($country: Country!, $providerId: ID!) {
  readCSPackageDetails(country: $country, providerId: $providerId) {
    code
    success
    message
    data {
      _id
      country
      period
      isFreeTrial
      packageKey
      startDate
      dueDate
      isActive
      packageDetails {
        _id
        package
        providerId
        price
        currency
        maxStudents
        maxBranches
      }
    }
  }
}
`

export const READ_TICKET_DETAILS = gql`
query ReadTicketDetails($ticketId: ID!) {
  readTicketDetails(ticketId: $ticketId) {
    code
    message
    success
    data {
      _id
      source {
        key
        value
      }
      platform {
        key
        value
      }
      customerId {
        _id
        aId
        email
        firstName
        lastName
        phoneNumber
        countryCode
        profilePictureUrl
      }
      ticketReason {
        key
        value
      }
      ticketNumber
      status
      isOpened
      email
      createdAt
      updatedAt
      comment,
      country
      agentId {
        _id
        firstName
        lastName
        role
        email
        phoneNumber
      }
      employeeId {
        firstName
        lastName
      }
    }
  }
}
`

export const READ_GENERAL_INFORMATION_USER_DATA = gql`
query ReadUserData($country: Country!, $phoneNumber: String, $email: String) {
  readUserData(country: $country, phoneNumber: $phoneNumber, email: $email) {
    code
    message
    success
    data {
      _id
      aId
      address {
        area
        city {
          key
          value
        }
        country {
          key
          value
        }
        governorate {
          key
          value
        }
        postalCode
        street
      }
      countryCode
      countryOfCitizenship {
        key
        value
      }
      dateOfBirth
      email
      firstName
      gender {
        key
        value
      }
      lastName
      nationalId
      passportId
      phoneNumber
      legalGuardians {
        relationshipToUser {
          key
          value
        }
        countryCode
        countryOfCitizenship {
          key
          value
        }
        dateOfBirth
        email
        firstName
        gender {
          key
          value
        }
        lastName
        nationalId
        passportId
        phoneNumber
      }
      roles {
        role
        providerData {
          name
        }
      }
      branchesData {
        branchId
        branchName
        groupKey {
          key
          value
          id
        }
        subGroupId {
          id
          key
          value
        }
      }
    }
  }
}
`
export const READ_USER_REQUESTS = gql`
query ReadUserRequests($status: RequestStatus, $branchId: ID, $commitedBy: [ID], $startDate: Date, $endDate: Date, $country: Country!, $userId: ID!, $page: Int!, $limit: Int!) {
  readUserRequests(status: $status, branchId: $branchId, commitedBy: $commitedBy, startDate: $startDate, endDate: $endDate, country: $country, userId: $userId, page: $page, limit: $limit) {
    code
    message
    success
    data {
      pagination {
        totalDocuments
        viewLimit
      }
      list {
        _id
        branch {
          aId
          branchType
          name
        }
        receiver {
          firstName
          lastName
          aId
        }
        commitedBy {
          firstName
          lastName
          countryCode
          dateOfBirth
          email
          gender {
            key
            value
          }
          nationalId
          passportId
          phoneNumber
          aId
          _id
          address {
            city {
              key
              value
            }
            country {
              key
              value
            }
            governorate {
              key
              value
            }
          }
          countryOfCitizenship {
            key
            value
          }
        }
        isHyperLink
        createdAt
        requestType
        status
        provider {
          name
          aId
        }
        senderType
        senderData {
          firstName
          lastName
        }
      }
    }
  }
}
`;

export const READ_REQUESTS_FILTER_DROPDOWNS = gql`
query ReadUserRequests($country: Country!, $page: Int!, $limit: Int!, $userId: ID!) {
  readUserRequests(country: $country, page: $page, limit: $limit, userId: $userId) {
    code
    message
    success
    data {
      filters {
        branches {
          _id
          name
        }
        commitedBy {
          _id
          firstName
          lastName
        }
        status
      }
    }
  }
}
`

export const READ_DASHBOARD_STATISTICS = gql`
query ReadTicketsStatistics($startDate: Date!, $country: Country!, $endDate: Date) {
  readTicketsStatistics(startDate: $startDate, country: $country, endDate: $endDate) {
    code
    success
    message
    data {
      totalTickets
      opened
      hold
      closed
    }
  }
}
`;

export const READ_DASHBOARD_ALLCOUNTRIES = gql`
query ReadTicketsStatisticsInMonths($year: Int!, $country: Country!) {
  readTicketsStatisticsInMonths(year: $year, country: $country) {
    code
    success
    message
    data {
      month
      count
    }
  }
}
`;

export const READ_DASHBOARD_TICKETS_PROBLEMS = gql`
query ReadTicketsProblemsStatistics($startDate: Date!, $country: Country!, $endDate: Date) {
  readTicketsProblemsStatistics(startDate: $startDate, country: $country, endDate: $endDate) {
    code
    success
    message
    data {
      problem
      totalCount
    }
  }
}
`;
export const READ_DASHBOARD_TICKETS_SOURCE = gql`
query ReadTicketsSourceStatistics($startDate: Date!, $country: Country!, $endDate: Date) {
  readTicketsSourceStatistics(startDate: $startDate, country: $country, endDate: $endDate) {
    code
    success
    message
    data {
      source
      totalCount
    }
  }
}
`;
export const READ_DASHBOARD_TICKETS_BY_MOBILE = gql`
query ReadTicketsStatisticsByMobileType($year: Int!, $country: Country!) {
  readTicketsStatisticsByMobileType(year: $year, country: $country) {
    code
    success
    message
    data {
      android {
        month
        count
      }
      iOS {
        month
        count
      }
    }
  }
}
`;

export const READ_USERS_MEMBERS = gql`
query ReadUserMembers($country: Country!, $userId: ID!) {
  readUserMembers(country: $country, userId: $userId) {
    code
    message
    success
    data {
      isStudent
      subUser {
        _id
        aId
        firstName
        lastName
        email
        dateOfBirth
        nationalId
        passportId
        profilePictureUrl
        countryCode
        phoneNumber
        isVerified
        gender {
          key
          value
        }
        legalGuardians {
          countryCode
          countryOfCitizenship {
            key
            value
          }
          dateOfBirth
          email
          firstName
          gender {
            key
            value
          }
          lastName
          nationalId
          passportId
          phoneNumber
          relationshipToUser {
            key
            value
          }
        }
        countryOfCitizenship {
          key
          value
        }
        roles {
          role
        }
        address {
          area
          city {
            key
            value
          }
          country {
            key
            value
          }
          governorate {
            key
            value
          }
          street
          postalCode
        }
        branchesData {
          branchId
          branchName
          groupKey {
            key
            value
            id
          }
          subGroupId {
            key
            value
            id
          }
        }
      }
      defaultPasswordUsed
    }
  }
}
`
export const READ_USER_CONNECTIONS = gql`
query ReadUserConnections($country: Country!, $userId: ID!) {
  readUserConnections(country: $country, userId: $userId) {
    data {
      _id
      aId
      firstName
      lastName
      phoneNumber
      email
      profilePictureUrl
      
    }
    code
    message
    success
  }
}
`


export const READ_STUDENT_BASIC_QUERIES = gql`
query ReadStudentBasicQueries($branchId: ID!,$country: CountryOfResidence!) {
  readCountries {
    code
    success
    message
    data {
        key
    value
    code
    }
  }
  readGenders {
    code
    success
    message
    data {
      id
    key
    value
    }
  }
  readRelationshipsToUser {
    key
    value
  }
  readSchoolGrades(branchId: $branchId, country: $country) {
    code
    success
    message
    data {
    _id
    groupKey
    groupValue
    isAssigned
    title
    }
  }
}
`
export const READ_GENDERS_COUNTRIES_DROPDOWNS = gql`
query ReadDropdownsBasicQueries($country: CountryOfResidence!) {
  readGenders {
    code
    success
    message
    data {
      id
    key
    value
    }
  }
    readCountries {
      code
      success
      message
      data {
        key
        value
      }
    }
  
    readGovernorates(country: $country) {
    code
    success
    message
    data {
         key
    value
    }
  }
  
}
`
export const READ_GOVERNERATE = gql`
query ReadGovernorates($country: CountryOfResidence!) {
  readGovernorates(country: $country) {
    code
    success
    message
    data {
      key
      value
    }
  }
}
`
export const READ_CITIES_BY_GOVERNORATE = gql`
query ReadCitiesByGovernorate($governorate: String!, $country: CountryOfResidence!) {
  readCitiesByGovernorate(governorate: $governorate, country: $country) {
    code
    message
    success
    data {
      key
      value
    }
  }
}
`

// export const READ_GOVERNORATE_BY_COUNTRY = gql `

// `

export const READ_SUBGROUPS_IN_GROUP = gql`
query ReadSubGroupsInAGroup($country: CountryOfResidence!, $branchId: ID!, $groupKey: String!) {
  readSubGroupsInAGroup(country: $country, branchId: $branchId, groupKey: $groupKey) {
    code
    success
    message
    data {
        _id
    title
    name
    maxUsers
    }
  }
}
`
export const READ_CEQUENS_ACCOUNT_BALANCE = gql`
query ReadCequensAccountBalance {
  readCequensAccountBalance {
    code
    success
    message
    data {
      balance
      messageCount
    }
  }
}
`

export const READ_ALL_TICKETS = gql`
query ReadTicketsDashboard($page: Int!, $searchInput: Int, $filterInput: TicketFilterInput) {
  readTicketsDashboard(page: $page, searchInput: $searchInput, filterInput: $filterInput) {
    code
    success
    message
    data {
      list {
        _id
        country
        source {
          key
          value
        }
        platform {
          key
          value
        }
        comment
        sendNotifaction
        customerId {
          firstName
          lastName
          aId
          phoneNumber
          countryCode
        }
        ticketReason {
          key
          value
        }
        ticketNumber
        agentId {
          firstName
          lastName
        }
        status
        isOpened
        email
        createdBy
        createdAt
        updatedAt
        employeeId {
          firstName
          lastName
          phoneNumber
          countryCode

        }
      }
      pagination {
        totalDocuments
        viewLimit
      }
    }
  }
}
`
export const READ_CEQUENS_MESSAGES = gql`
query ReadCequensMessages($phoneNumber: String!, $countryCode: String!, $startDate: Date!, $endDate: Date!) {
  readCequensMessages(phoneNumber: $phoneNumber, countryCode: $countryCode, startDate: $startDate, endDate: $endDate) {
    code
    success
    message
    data {
      Recepient
      Sender
      SentDate
      Status
      AccountId
      RequestId
    }
  }
}
`

export const READ_FILTER_TICKET_QUERIES = gql`
query ReadFiltredTicketQueries {
  readAllCSUsers {
    code
    success
    message
    data {
      _id
      firstName
      lastName
    }
  }
  readPlatform {
    code
    success
    message
    data {
      key
      value
    }
  }
  readSource {
    code
    success
    message
    data {
      key
      value
    }
  }
}
`

export const READ_CLIENT_DATA = gql`
query ReadClientsData($country: Country,$page: Int!, $searchInput: String, $filterInput: ClientFilterInput) {
  readClientsData(country: $country,page: $page, searchInput: $searchInput, filterInput: $filterInput) {
    code
    success
    message
    data {
      pagination {
        totalDocuments
        viewLimit
      }
      list {
        _id
        fullName
        aId
        phoneNumber
        email
        profilePictureUrl
        subusers
        isVerified
        createdAt
        countryCode
        platform {
          key
          value
        }
        status
        countryOfResidence
      }
      subusersMax
    }
  }
}
`

export const EXPORT_CLIENTS_DATA = gql`
query ReadClientsData($page: Int!, $searchInput: String, $filterInput: ClientFilterInput) {
  readClientsData(page: $page, searchInput: $searchInput, filterInput: $filterInput) {
    code
    success
    message
    data {
      pagination {
        totalDocuments
        viewLimit
      }
      list {
        _id
        fullName
        aId
        phoneNumber
        email
        profilePictureUrl
        subusers
        isVerified
        createdAt
        countryCode
        platform {
          key
          value
        }
        status
        updatedAt
      }
    }
  }
}`

export const READ_ALL_MAILS = gql`
query ReadMails($ticketNumber: Int!) {
  readMails(ticketNumber: $ticketNumber) {
    code
    success
    message
    data {
      messageId
      from {
        address
        name
      }
      to {
        address
        name
      }
      cc {
        address
        name
      }
      subject
      date
      body
      attachments {
        filename
        url
        size
      }
      inReplyTo
      replies {
        messageId
        from {
          address
          name
        }
        to {
          address
          name
        }
        subject
        date
        body
        inReplyTo
        attachments {
          filename
          url
          size
        }
        replies {
          messageId
          from {
            address
            name
          }
          to {
            address
            name
          }
          cc {
            address
            name
          }
          subject
          date
          body
          attachments {
            filename
            url
            size
          }
          inReplyTo
        }
        cc {
          address
          name
        }
      }
    }
  }
}
`
export const READ_NOTIFICATIONS_LIST = gql`
query ReadMyMessages {
  readMyMessages {
    code
    success
    message
    data {
      _id
      subject
      body
 
      recipient {
        userId
        readAt
        deletedAt
        isRead
        isDeleted
      }
      ticketId {
        _id
        country
        source {
          key
          value
        }
        platform {
          key
          value
        }
        comment
        sendNotifaction
        customerId {
          _id
          firstName
          lastName
          phoneNumber
          countryCode
          email
          profilePictureUrl
          aId
        }
        ticketReason {
          key
          value
        }
        ticketNumber
        agentId {
          _id
          firstName
          lastName
          phoneNumber
          email
          profilePictureUrl
          role
          dateOfHiring
          countryCode
          createdAt
          updatedAt
        }
        employeeId {
          _id
          firstName
          lastName
          phoneNumber
          email
          profilePictureUrl
          role
          dateOfHiring
          countryCode
          createdAt
          updatedAt
        }
        status
        isOpened
        email
        createdBy
        TransferReason
        createdAt
        updatedAt
      }
      updatedAt
      createdAt
      senderId {
        _id
        firstName
        lastName
        phoneNumber
        email
        profilePictureUrl
        role
        dateOfHiring
        countryCode
        createdAt
        updatedAt
      }
    }
  }
}
`
export const READ_SINGLE_NOTIFICATION = gql`
query ReadMyCSMessageDetails($messageId: ID!) {
  readMyCSMessageDetails(messageId: $messageId) {
    code
    success
    message
    data {
      _id
      subject
      body
      senderId {
        _id
        firstName
        lastName
        phoneNumber
        email
        profilePictureUrl
        role
        dateOfHiring
        countryCode
        createdAt
        updatedAt
      }
      recipient {
        userId
        readAt
        deletedAt
        isRead
        isDeleted
      }
      ticketId {
        _id
        country
        source {
          value
          key
        }
        platform {
          value
          key
        }
        comment
        sendNotifaction
        customerId {
          _id
          firstName
          lastName
          phoneNumber
          countryCode
          email
          profilePictureUrl
          aId
        }
        ticketReason {
          id
          key
          value
          color
          logo
        }
        ticketNumber
        agentId {
          _id
          firstName
          lastName
          phoneNumber
          email
          profilePictureUrl
          role
          dateOfHiring
          countryCode
          createdAt
          updatedAt
        }
        employeeId {
          _id
          firstName
          lastName
          phoneNumber
          email
          profilePictureUrl
          role
          dateOfHiring
          countryCode
          createdAt
          updatedAt
        }
        status
        isOpened
        email
        createdBy
        TransferReason
        createdAt
        updatedAt
      }
      updatedAt
      createdAt
    }
  }
}
`
export const READ_ALL_ORGANIZATIONS = gql`
query ReadAllOrganizations($country: Country!, $page: Int, $startDate: Date, $endDate: Date, $searchInput: String) {
  readAllOrganizations(country: $country, page: $page, startDate: $startDate, endDate: $endDate, searchInput: $searchInput) {
    code
    success
    message
    data {
      list {
        _id
        aId
        name
        email
        logoUrl
        country
        branchesCount
        totalStudents
        createdAt
      }
      pagination {
        totalDocuments
        viewLimit
      }
    }
  }
}`
export const READ_EDUCATION_TYPE = gql`
query ReadEducationTypes($country: CountryOfResidence!) {
  readEducationTypes(country: $country) {
    code
    success
    message
    data {
      key
      value
    }
  }
}
`
export const UPDAE_ORGANIZATION_NAME = gql`
mutation UpdateOrganization($providerId: ID!, $name: String!) {
  updateOrganization(providerId: $providerId, name: $name) {
    code
    success
    message
  }
}`

export const COUNT_UNREAD_MESSAGES = gql`
query ReadUnreadMessagesCount {
  readUnreadMessagesCount {
    code
    success
    message
    data
  }
}
`
export const READ_INVOICES = gql`query ReadCSInvoices($country: Country!, $providerId: ID!, $subscriptionId: ID!, $page: Int!) {
  readCSInvoices(country: $country, providerId: $providerId, subscriptionId: $subscriptionId, page: $page) {
    code
    success
    message
    data {
      list {
        _id
        subscriptionId
        packagePrice
        providerId
        amount
        currency
        dueDate
        studentsCount
        status
        bankTransferPhoto
        paymentDate
        address {
          _id
          country {
            value
            key
          }
          governorate {
            value
            key
          }
          city {
            value
            key
          }
          area
          street
          postalCode
        }
        package
        userName
        logoUrl
        createdAt
        updatedAt
      }
      pagination {
        totalDocuments
        viewLimit
      }
    }
  }
}`

export const  READ_USER_ORGANIZATIONS=gql`
query ReadUserOrganizations($country: Country!, $userId: ID!) {
  readUserOrganizations(country: $country, userId: $userId) {
    code
    success
    message
    data {
      name
      _id
      aId
      logoUrl
      role
    }
  }
}`
