import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classes from './Textarea.module.scss';

const Textarea = ({
    type = "text",
    name,
    placeholder,
    value,
    rows,
    cols,
    maxLength,
    areaStyle,
    style,
    required = false,
    disabled = false,
    readOnly = false,
    onChange = () => null,
    onKeyPress = () => null,
    borderStyle 
}) => {
    const language = useSelector((state) => state.auth.language);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        if (value === "" || value === null) {
            setIsActive(false)
        }
        else {
            setIsActive(true)
        }
    }, [value])

    return (
        <div className={classes.container} style={style}>
            <label className={(isActive || value) && classes.inputFocus} htmlFor={name} style={language === 'ar' ? { right: "3%" } : null}>
                {placeholder} {required && <span style={{ color: "var(--errorColor)", textDecoration: "none" }}> *</span>}
            </label>
            <textarea
                type={type}
                id={name}
                name={name}
                rows={rows}
                cols={cols}
                maxLength={maxLength}
                value={value}
                disabled={disabled}
                readOnly={readOnly}
                style={{ ...areaStyle, ...borderStyle, resize: "none" }}
                
                onChange={(e) => onChange(e)}
                onBlur={() => {
                    setIsActive(value || false)
                    if (value === 0) {
                        setIsActive(true)
                    }
                }}
                onFocus={() => setIsActive(true)}
                onKeyPress={(e) => onKeyPress(e)}
            // style={{
            //     borderColor: error ? "var(--errorColor)" : "#dddddd",
            // }}
            />
        </div>
    )
}

export default Textarea;