import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    ticketInfo: sessionStorage.getItem('ticketInfo') ? JSON.parse(sessionStorage.getItem('ticketInfo')) : null,
    hasOrganizations: sessionStorage.getItem('hasOrganizations') ? JSON.parse(sessionStorage.getItem('hasOrganizations')) : false,
    currentBranchRole: sessionStorage.getItem('currentBranchRole') ? JSON.parse(sessionStorage.getItem('currentBranchRole')) : '',
    notificationNum: sessionStorage.getItem('notificationNum') ? JSON.parse(sessionStorage.getItem('notificationNum')) : 0,
    personalInfoChanged: "",

};

export const ticketInfoSlice = createSlice({
    name: 'ticketInfo',
    initialState,
    reducers: {
        saveTicketInfo: (state, action) => {
            state.ticketInfo = action.payload;
            sessionStorage.setItem('ticketInfo', JSON.stringify(action.payload));
        },
        clearTicketInfo: (state) => {
            state.ticketInfo = null;
            sessionStorage.removeItem('ticketInfo');
        },
        savehavingOrganization: (state, action) => {
            state.hasOrganizations = action.payload;
            sessionStorage.setItem('hasOrganizations', JSON.stringify(action.payload));
        },
        clearHavingOrganization: (state) => {
            state.hasOrganizations = false;
            sessionStorage.removeItem('hasOrganizations');
        },
        saveCurrentBranchRole: (state, action) => {
            state.currentBranchRole = action.payload;
            sessionStorage.setItem('currentBranchRole', JSON.stringify(action.payload));
        },
        saveNotificationNum: (state, action) => {
            state.notificationNum = action.payload;
            sessionStorage.setItem('notificationNum', JSON.stringify(action.payload));
        },
        savePersonalInfoChanged: (state, action) => {
            state.personalInfoChanged = action.payload
        }
    },
});

export const { saveTicketInfo, savehavingOrganization, clearHavingOrganization, savePersonalInfoChanged, saveNotificationNum, clearTicketInfo, saveCurrentBranchRole } = ticketInfoSlice.actions;
export const ticketInfoReducer = ticketInfoSlice.reducer;