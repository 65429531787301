const words = {
  LANGUAGE: {
    ar: "لغة",
    en: "Language",
    tu: "Dil",
    ru: "Язык",
    fr: "Langue"
  },
  LOG_OUT: {
    ar: "تسجيل الخروج",
    en: "Log Out",
    tu: "Çıkış Yap",
    ru: "Выйти",
    fr: "Se déconnecter",
  },
  //___________________Sidebars________________
  AMS: {
    ar: "نظام إدارة",
    en: "AMS",
    tu: "AMS",
    ru: "AMS",
    fr: "AMS",
  },
  DASHBOARD: {
    ar: "لوحة القيادة",
    en: "Dashboard",
    tu: "",
    ru: "",
    fr: "",
  },
  
  GENERAL_INFORMATION: {
    ar: "معلومات عامة",
    en: "General Information",
    tu: "",
    ru: "",
    fr: "",
  },
  MEMBERS_AND_CONNECTIONS: {
    ar: "الاعضاء والاتصالات",
    en: "Members & Connections",
    tu: "",
    ru: "",
    fr: "",
  },
  SECURITY: {
    ar: "الأمان",
    en: "Security",
    tu: "",
    ru: "",
    fr: "",
  },
  ORGANIZATION_INFO: {
    ar: "معلومات المنظمة",
    en: "Organization Info",
    tu: "",
    ru: "",
    fr: "",
  },
  ORGANIZATION_DATA: {
    ar: "معلومات المنظمة",
    en: "Organization Data",
    tu: "",
    ru: "",
    fr: "",
  },
  PACKAGE_DETAILS: {
    ar: "تفاصيل الحزمة",
    en: "Package Details",
    tu: "",
    ru: "",
    fr: "",
  },
  
  //___________________Dashboard_Page________________

  HOME: {
    ar: " الصفحة الرئيسية",
    en: "Home",
    tu: "",
    ru: "",
    fr: "",
  },
  ANDROID: {
    ar: "اندرويد",
    en: "Android",
    tu: "",
    ru: "",
    fr: "",
  },
  IOS: {
    ar: "اي او اس",
    en: "IOS",
    tu: "",
    ru: "",
    fr: "",
  },
  ON_HOLD: {
    ar: "قيد الانتظار",
    en: "On Hold",
    tu: "",
    ru: "",
    fr: "",
  },
  OPENED: {
    ar: "افتتحت",
    en: "Opened",
    tu: "",
    ru: "",
    fr: "",
  },
  CLOSED: {
    ar: "اغلقت",
    en: "Closed",
    tu: "",
    ru: "",
    fr: "",
  },
  BAD: {
    ar: "سئ",
    en: "Bad",
    tu: "",
    ru: "",
    fr: "",
  },
  AVERAGE: {
    ar: "عادي",
    en: "Average",
    tu: "",
    ru: "",
    fr: "",
  },
  GOOD: {
    ar: "جيد",
    en: "Good",
    tu: "",
    ru: "",
    fr: "",
  },
  EXCELLENT: {
    ar: "ممتاز",
    en: "Excellent",
    tu: "",
    ru: "",
    fr: "",
  },
  UPGRADE: {
    ar: "ترقية",
    en: "Upgrade",
    tu: "",
    ru: "",
    fr: "",
  },
  ACTIVATED: {
    ar: "مفعل",
    en: "Activated",
    tu: "",
    ru: "",
    fr: "",
  },
  DEACTIVATED: {
    ar: "معطل",
    en: "Deactivated",
    tu: "",
    ru: "",
    fr: "",
  },
  UPDATE_NATONAL_ID: {
    ar: "تعديل الرقم القومي",
    en: "Update National ID",
    tu: "",
    ru: "",
    fr: "",
  },
  UPDATE_PASSPORT_ID: {
    ar: "تحديث رقم جواز السفر ",
    en: "Update Passport ID",
    tu: "",
    ru: "",
    fr: "",
  },
  ARE_YOU_SURE_YOU_ANT_TO_UPDATE_PASSPORT_ID: {
    ar: "هل أنت متأكد من أنك تريد تحديث رقم جواز السفر ؟",
    en:"Are You Sure You Want To Update  Passport ID?",
    tu: "",
    ru: "",
    fr: "",
  },
  ARE_YOU_SURE_YOU_ANT_TO_UPDATE_HIS_NATIONAL_ID: {
    ar: "هل أنت متأكد من أنك تريد تحديث هويته الوطنية؟",
    en:"Are You Sure You Want To Update His National ID?",
    tu: "",
    ru: "",
    fr: "",
  },
  ARE_YOU_SURE_YOU_ANT_TO_UPDATE_HER_NATIONAL_ID: {
    ar: "هل أنت متأكد من أنك تريد تحديث هويتها الوطنية؟",
    en: "Are You Sure You Want To Update Her National ID?",
    tu: "",
    ru: "",
    fr: "",
  },
  NOTE: {
    ar: "ملحوظة",
    en: "Note",
    tu: "",
    ru: "",
    fr: "",
  },
  ALL_MEMBERS_WILL_BE_DELETED: {
    ar: "سيتم حذف جميع الأعضاء",
    en: "All Members Will Be Deleted",
    tu: "",
    ru: "",
    fr: "",
  },
  //___________________Organizations_Page________________
  ORGANIZATIONS: {
    ar: "المنظمات",
    en: "Organizations",
    tu: "",
    ru: "",
    fr: "",
  },
  BRANCHES: {
    ar: "الفروع",
    en: "Branches",
    tu: "",
    ru: "",
    fr: "",
  },
  FILTER: {
    ar: "تصفيه",
    en: "Filter",
    tu: "",
    ru: "",
    fr: "",
  },
  BRANCH: {
    ar: "الفرع",
    en: "Branch",
    tu: "",
    ru: "",
    fr: "",
  },
  DATE: {
    ar: "التاريخ",
    en: "Date",
    tu: "Tarih",
    ru: "Дата",
    fr: "Date",
  },
  COUNTRY: {
    ar: "البلد",
    en: "Country",
    tu: "",
    ru: "",
    fr: "",
  },
  BRANCHES: {
    ar: "الفروع",
    en: "Branches",
    tu: "",
    ru: "",
    fr: "",
  },
  STUDENTS: {
    ar: " الطلاب",
    en: "Students",
    tu: "",
    ru: "",
    fr: "",
  },
  ACTION: {
    ar: " اجراء",
    en: "Action",
    tu: "",
    ru: "",
    fr: "",
  },
  NO_ORGANIZATIONS_TO_DISPLAY: {
    ar: " لا يوجد منظمات لعرضها",
    en: "There are no organizations to display",
    tu: "",
    ru: "",
    fr: "",
  },
  EDIT_ORGANIZATION: {
    en: "Edit Organization",
    ar: "تعديل المنظمة",
    tu: "",
    ru: "",
    fr: "",
  },
  GENERAL_INFO: {
    en: "General Info",
    ar: "معلومات عامة",
    tu: "",
    ru: "",
    fr: "",
  },
  ORGANIZATION_NAME: {
    en: "Organization Name",
    ar: "اسم المنظمة",
    tu: "",
    ru: "",
    fr: "",
  },
  HEADQUARTER: {
    en: "Headquarter",
    ar: "المقر الرئيسي",
    tu: "",
    ru: "",
    fr: "",
  },
  AREA: {
    en: "Area",
    ar: "المنطقة",
    tu: "",
    ru: "",
    fr: "",
  },
  EDUCATION_TYPE: {
    en: "Education Type",
    ar: "نوع التعليم",
    tu: "",
    ru: "",
    fr: "",
  },
  //___________________Invoice_Page________________
  INVOICE_ID: {
    ar: "رقم الفاتورة",
    en: "Invoice ID",
    tu: "",
    ru: "",
    fr: "",
  },
  INVOICE: {
    ar: "الفاتورة",
    en: "Invoice",
    tu: "",
    ru: "",
    fr: "",
  },
  NUMBER_STUDENTS: {
    ar: "عدد الطلاب",
    en: "No.Students",
    tu: "",
    ru: "",
    fr: "",
  },
  LAST_TRANSITION: {
    ar: "اخر عملية",
    en: "Last transition",
    tu: "",
    ru: "",
    fr: "",
  },
  TYPE: {
    ar: "النوع",
    en: "Type",
    tu: "",
    ru: "",
    fr: "",
  },
  DOWNLOAD: {
    ar: "تحميل",
    en: "Download",
    tu: "",
    ru: "",
    fr: "",
  },
  PRINT: {
    ar: "طباعة",
    en: "Print",
    tu: "",
    ru: "",
    fr: "",
  },
  NO_INVOICES_TO_DISPLAY: {
    ar: "لا توجد فواتير لعرضها",
    en: "There are no invoices to display",
    tu: "",
    ru: "",
    fr: "",
  },
  THE_RIGHT_PLAN: {
    en: "The Right Plan For Your Business",
    ar: "الخطة المناسبة لعملك",
    tu: "Zaten var",
    ru: "Уже есть",
    fr: "Déjà avoir un",
  },
  WE_HAVE_POWERFUL_PLANS: {
    en: "We have powerful plans to Showcase your business and get discovered as a creative entrepreneurs. Every thing you need.",
    ar: "لدينا خطط قوية لعرض أعمالك واكتشافها كأحد رواد الأعمال المبدعين. كل ما تحتاجه",
    tu: "Zaten var",
    ru: "Уже есть",
    fr: "Déjà avoir un",
  },
  BRONZE: {
    en: "Bronze",
    ar: "برونزية",
    tu: "Zaten var",
    ru: "Уже есть",
    fr: "Déjà avoir un",
  },
  SILVER: {
    en: "Silver",
    ar: "فضية",
    tu: "Zaten var",
    ru: "Уже есть",
    fr: "Déjà avoir un",
  },
  GOLD: {
    en: "Gold",
    ar: "ذهبية",
    tu: "Zaten var",
    ru: "Уже есть",
    fr: "Déjà avoir un",
  },
  PLATINIUM: {
    en: "Platinium",
    ar: "البلاتينيوم",
    tu: "Zaten var",
    ru: "Уже есть",
    fr: "Déjà avoir un",
  },
  DOWNGRADE: {
    en: "Downgrade",
    ar: "تخفيض",
    tu: "Zaten var",
    ru: "Уже есть",
    fr: "Déjà avoir un",
  },
  UPGRADE: {
    en: "Upgrade",
    ar: "ترقية",
    tu: "Zaten var",
    ru: "Уже есть",
    fr: "Déjà avoir un",
  },
  PACKAGE: {
    en: "Package",
    ar: "حزمة",
    tu: "Zaten var",
    ru: "Уже есть",
    fr: "Déjà avoir un",
  },
  RENEWABLE_DATE: {
    en: "Renewable Date",
    ar: "تاريخ التجديد",
    tu: "Zaten var",
    ru: "Уже есть",
    fr: "Déjà avoir un",
  },
  ANNUALLY: {
    en: "Annually",
    ar: "سنويا",
    tu: "Zaten var",
    ru: "Уже есть",
    fr: "Déjà avoir un",
  },

  //___________________Branches_Page________________
  BRANCHE: {
    ar: " الفرع",
    en: "Branch",
    tu: "",
    ru: "",
    fr: "",
  },
  F_GUARDIEANS: {
    ar: " ولي الامر الاول",
    en: "F.Guardians",
    tu: "",
    ru: "",
    fr: "",
  },
  S_GUARDIEANS: {
    ar: " ولي الامر الثاني",
    en: "S. Guardians",
    tu: "",
    ru: "",
    fr: "",
  },
  NO_BRANCHES_TO_DISPLAY: {
    ar: " لا يوجد منظمات لعرضها",
    en: "There are no Branches to display",
    tu: "",
    ru: "",
    fr: "",
  },
  ACTIVATE_ACCOUNT: {
    ar: "تفعيل الحساب",
    en: "Activate Account",
    tu: "",
    ru: "",
    fr: "",
  },
  DECLINED: {
    ar: "رفض",
    en: "declined",
    tu: "",
    ru: "",
    fr: "",
  },
  //___________________Verfication_Modal________________
  ENTER_OTP: {
    ar: "ادخل رمز التحقق",
    en: "Enter OTP",
    tu: "",
    ru: "",
    fr: "",
  },
  CONTINUE: {
    ar: "متابعة ",
    en: "Continue",
    tu: "",
    ru: "",
    fr: "",
  },
  RESEND_CODE: {
    ar: "إعادة إرسال الرمز",
    en: "Resend Code",
    tu: "",
    ru: "",
    fr: "",
  },

    //___________________Clients_Page________________
    CLIENTS: {
      en: "Clients",
      ar: "العملاء",
      tu: "",
      ru: "",
      fr: "",
    },
    CLIENT: {
      en: "Client",
      ar: "العميل",
      tu: "",
      ru: "",
      fr: "",
    },
    EXPORT: {
      en: "Export",
      ar: "تصدير",
      tu: "",
      ru: "",
      fr: "",
    },
    EMAIL: {
      en: "Email",
      ar: "البريد الالكتروني",
      tu: "",
      ru: "",
      fr: "",
    },
    TO: {
      en: "To",
      ar: "الى",
      tu: "",
      ru: "",
      fr: "",
    },
    SUBJECT: {
      en: "Subject",
      ar: "الموضوع",
      tu: "",
      ru: "",
      fr: "",
    },
    
    SUB_USERS: {
      en: "Sub users",
      ar: "المستخدمين الفرعيين",
      tu: "",
      ru: "",
      fr: "",
    },
    DEVICE: {
      en: "Device",
      ar: "الجهاز ",
      tu: "",
      ru: "",
      fr: "",
    },
    USER: {
      en: "User",
      ar: "العميل",
      tu: "",
      ru: "",
      fr: "",
    },
    NO_CLIENTS_TO_DISPLAY: {
      en: "There are no clients to display",
      ar: "لا يوجد عملاء لعرضها",
      tu: "",
      ru: "",
      fr: "",
    },
  //___________________Clients_Filter_Modal________________
  FILTER_CLIENTS: {
    en: "Filter Clients",
    ar: "تصفية العملاء",
    tu: "",
    ru: "",
    fr: "",
  },
  REGISTED: {
    en: "Registered",
    ar: "مسجل",
    tu: "",
    ru: "",
    fr: "",
  },
  SUBUSER: {
    en: "Subuser",
    ar: "مستخدم فرعي",
    tu: "",
    ru: "",
    fr: "",
  },
  TOTAL_CLIENTS: {
    en: "Total Clients",
    ar: "إجمالي العملاء",
    tu: "",
    ru: "",
    fr: "",
  },
  //___________________Pagination________________
  DISPLAY: {
    en: "Display",
    ar: "عرض",
    tu: "Sonraki",
    ru: "Далее",
    fr: "Suivant",
  },
  DISPLAYED: {
    en: "Displayed",
    ar: "معروض",
    tu: "Sonraki",
    ru: "Далее",
    fr: "Suivant",
  },
  OUT_OF: {
    en: "Out Of",
    ar: "من",
    tu: "Sonraki",
    ru: "Далее",
    fr: "Suivant",
  },
  ADD: {
    en: "Add",
    ar: "اضف",
    tu: "Sonraki",
    ru: "Далее",
    fr: "Suivant",
  },
  EDIT: {
    en: "Edit",
    ar: "تعديل",
    tu: "Sonraki",
    ru: "Далее",
    fr: "Suivant",
  },
  NEXT: {
    en: "Next",
    ar: "التالي",
    tu: "Sonraki",
    ru: "Далее",
    fr: "Suivant",
  },
  PREVIOUS: {
    en: "Previous",
    ar: "السابق",
    tu: "Sonraki",
    ru: "Далее",
    fr: "Suivant",
  },

  //_______________Filter_Organization_Modal______________

  FILTER_ORGANIZATION: {
    en: "Filter Organization",
    ar: "تصفية المنظمة",
    tu: "",
    ru: "",
    fr: "",
  },
  ORGANIZATION: {
    en: "Organization",
    ar: "منظمة ",
    tu: "",
    ru: "",
    fr: "",
  },
  TOTAL_ORGANIZATIONS: {
    en: "Total Organizations",
    ar: "إجمالي المنظمات",
    tu: "",
    ru: "",
    fr: "",
  },
  RESET: {
    en: "RESET",
    ar: "إعادة تعيين",
    tu: "",
    ru: "",
    fr: "",
  },
  //_______________Search modal______________
  AID: {
    en: "AID",
    ar: "رقم المستخدم",
    tu: "",
    ru: "",
    fr: "",
  },
  PHONE: {
    en: "Phone",
    ar: "رقم الهاتف",
    tu: "",
    ru: "",
    fr: "",
  },
  NID_PPN: {
    en: "NID/PPN",
    ar: "الرقم القومي او رقم الباسبور",
    tu: "NID/PPN",
    ru: "NID/PPN",
    fr: "NID/PPN",
  },
  NID_IQAMA_ID: {
    en: "NID/IQAMAID",
    ar: "رقم الهوية او رقم الإقامة",
    tu: "NID/Iqama ID",
    ru: "NID/Iqama ID",
    fr: "NID/Iqama ID",
  },

  //_______________All_Tickets_Page______________
  ALL_TICKETS: {
    en: "All Tickets",
    ar: "جميع التذاكر",
    tu: "",
    ru: "",
    fr: "",
  },
  TICKET_DETAILS: {
    en: "Ticket Details",
    ar: "تفاصيل التذكرة",
    tu: "",
    ru: "",
    fr: "",
  },
  CUSTOMER: {
    en: "Customer",
    ar: "العميل",
    tu: "",
    ru: "",
    fr: "",
  },
  PHONE_NUMBER: {
    en: "Phone Number",
    ar: "رقم الهاتف",
    tu: "",
    ru: "",
    fr: "",
  },
  TICKET_NUMBER: {
    en: "Ticket.No",
    ar: "رقم التذكره",
    tu: "",
    ru: "",
    fr: "",
  },
  PLATFORM: {
    en: "Platform",
    ar: "المنصه",
    tu: "",
    ru: "",
    fr: "",
  },
  SOURCE: {
    en: " Source",
    ar: "المصدر",
    tu: "",
    ru: "",
    fr: "",
  },
  PROBLEM: {
    en: " Problem",
    ar: "المشكلة",
    tu: "",
    ru: "",
    fr: "",
  },
  STATUS: {
    en: " Status",
    ar: "الحالة",
    tu: "",
    ru: "",
    fr: "",
  },
  OPEN: {
    en: "Open",
    ar: "فتح",
    tu: "",
    ru: "",
    fr: "",
  },
  EMPLOYEE: {
    en: "Employee",
    ar: "الموظف",
    tu: "",
    ru: "",
    fr: "",
  },
  //_____________Tickets_page______________
  NO_TICKETS_AVAILABLE: {
    en: "No Tickets Available",
    ar: "لا توجد تذاكر متاحة",
    tu: "",
    ru: "",
    fr: "",
  },
  TICKETS: {
    en: "Tickets",
    ar: "تذاكر",
    tu: "",
    ru: "",
    fr: "",
  },
  NO_TICKETS_TO_DISPLAY: {
    en: "there are no tickets to display",
    ar: "الموظف",
      tu: "",
    ru: "",
    fr: "",
  },
  CUSTOMER: {
    en: "Customer",
    ar: "عميل",
    tu: "",
    ru: "",
    fr: "",
  },
  TICKET_NUMBER: {
    en: "Ticket Number",
    ar: "رقم التذكرة",
    tu: "",
    ru: "",
    fr: "",
  },
  TICKET_ADDED_SUCCESSFULLY: {
    en: "The Ticket has been added successfully",
    ar: "تمت إضافة التذكرة بنجاح",
    tu: "",
    ru: "",
    fr: "",
  },
  FILTER_TICKETS: {
    en: "Filter Tickets",
    ar: "تصفية التذاكر",
    tu: "",
    ru: "",
    fr: "",
  },
  FILTER_BY_DATE: {
    en: "Filter By Date",
    ar: "تصفية حسب التاريخ",
    tu: "",
    ru: "",
    fr: "",
  },
  TOTL_DEALS: {
    en: "Total Deals",
    ar: " إجمالي التعاملات",
    tu: "",
    ru: "",
    fr: "",
  },
  PHONE_NUMBER: {
    en: "Phone Number",
    ar: "رقم الهاتف",
    tu: "",
    ru: "",
    fr: "",
  },
  PERSONAL_INFO: {
    en: "Personal Info",
    ar: "معلومات شخصية",
    tu: "",
    ru: "",
    fr: "",
  },
  PERSONAL_INFORMATION: {
    en: "Personal Information",
    ar: "معلومات شخصية",
    tu: "",
    ru: "",
    fr: "",
  },
    //_____________Members______________
    MEMBERS: {
      en: "Members",
      ar: "أعضاء",
      tu: "",
      ru: "",
      fr: "",
    },
    ADD_MEMBER: {
      en: "Add Member",
      ar: "إضافة عضو",
      tu: "",
      ru: "",
      fr: "",
    },
    CONNECTIONS: {
      en: "Connections",
      ar: "الاتصالات",
      tu: "",
      ru: "",
      fr: "",
    },
    EDIT_SUB_USER: {
      en: "Edit Sub User",
      ar: "تعديل المستخدم الفرعي",
      tu: "",
      ru: "",
      fr: "",
    },
    DELETE_MEMEBER: {
      en: "Delete Member",
      ar: "حذف العضو",
      tu: "",
      ru: "",
      fr: "",
    },
    DELETE_CONNECTION: {
      en: "Delete Connection",
      ar: "حذف الاتصال",
      tu: "",
      ru: "",
      fr: "",
    },
    EDIT_STUDENT: {
      en: "Edit Student",
      ar: "تعديل الطالب",
      tu: "",
      ru: "",
      fr: "",
    },
    YES: {
      en: "Yes",
      ar: "نعم",
      tu: "",
      ru: "",
      fr: "",
    },
    NO: {
      en: "No",
      ar: "لا",
      tu: "",
      ru: "",
      fr: "",
    },
    DELETE_MEMBER_WARNING: {
      en: "Are You Sure you want to delete this member?",
      ar: "هل أنت متأكد أنك تريد حذف هذا العضو؟",
      tu: "",
      ru: "",
      fr: "",
    },
    DELETE_CONNECTION_WARNING: {
      en: "Are You Sure you want to delete this connection?",
      ar: "هل أنت متأكد أنك تريد حذف هذا الاتصال؟",
      tu: "",
      ru: "",
      fr: "",
    },
    SEARCH_MEMBER: {
      en: "Search Member",
      ar: "بحث عن عضو",
      tu: "",
      ru: "",
      fr: "",
    },
    ADD_A_NEW_MEMBER: {
      en: "Add A New Member",
      ar: "إضافة عضو جديد",
      tu: "",
      ru: "",
      fr: "",
    },
    ADD_MEMBER: {
      en: "Add Member",
      ar: "إضافة عضو",
      tu: "",
      ru: "",
      fr: "",
    },
  //_____________Logs_Modal______________
  LOGS: {
    en: "Logs",
    ar: "السجلات",
    tu: "",
    ru: "",
    fr: "",
  },
  SOURCE: {
    en: "Source",
    ar: "مصدر",
    tu: "",
    ru: "",
    fr: "",
  },
  TICKET: {
    en: "Ticket",
    ar: "تذكرة",
    tu: "",
    ru: "",
    fr: "",
  },
  TRANSFER_TICKET: {
    en: "Transfer Ticket",
    ar: "نقل التذكرة",
    tu: "",
    ru: "",
    fr: "",
  },
  NOTIFICATIONS: {
    en: "Notifications",
    ar: "الإشعارات",
    tu: "",
    ru: "",
    fr: "",
  },
  OPEN_TICKET: {
    en: "Open Ticket",
    ar: "فتح التذكرة",
    tu: "",
    ru: "",
    fr: "",
  },
  HAS_BEEN_TRANSFERRED_THIS_TICKET: {
    en: "has been transferred this Ticket",
    ar: "نقل هذه التذكرة",
    tu: "",
    ru: "",
    fr: "",
  },
  PLATFORM: {
    en: "Platform",
    ar: "منصة",
    tu: "",
    ru: "",
    fr: "",
  },
  EDITED: {
    en: "Edited",
    ar: "تم تعديله",
    tu: "",
    ru: "",
    fr: "",
  },
  ADD_TICKET: {
    en: "Add Ticket",
    ar: "إضافة تذكرة",
    tu: "",
    ru: "",
    fr: "",
  },
  OPENDED: {
    en: "Opened",
    ar: "تم فتحها",
    tu: "",
    ru: "",
    fr: "",
  },
  NEW_TICKETS: {
    en: "New Tickets",
    ar: "التذاكر الجديدة",
    tu: "",
    ru: "",
    fr: "",
  },
  SEND_EMAIL: {
    en: "Send Email",
    ar: "إرسال بريد إلكتروني",
    tu: "",
    ru: "",
    fr: "",
  },
  SEND: {
    en: "Send",
    ar: "إرسال",
    tu: "",
    ru: "",
    fr: "",
  },
  EMAIL: {
    en: "Email",
    ar: " البريد إلكتروني",
    tu: "",
    ru: "",
    fr: "",
  },
  REPLAY: {
    en: "Reply",
    ar: "رد",
    tu: "",
    ru: "",
    fr: "",
  },
  LATEST_MESSAGES: {
    en: "Latest Messages",
    ar: "أحدث الرسائل",
    tu: "",
    ru: "",
    fr: "",
  },
  TOTAL_TICKETS: {
    en: "Total Tickets",
    ar: "مجموع التذاكر",
    tu: "",
    ru: "",
    fr: "",
  },
  BY: {
    en: "By",
    ar: "بواسطة",
    tu: "",
    ru: "",
    fr: "",
  },
  NO_TICKETS: {
    en: "You don’t have Any Tickets",
    ar: "ليس لديك أي تذاكر",
    tu: "",
    ru: "",
    fr: "",
  },
  ON: {
    en: "On",
    ar: "في",
    tu: "",
    ru: "",
    fr: "",
  },
  SELECT_YOUR_COUNTRY: {
    en: "Select your country that we can provide you with best experience",
    ar: "حدد بلدك حتى نتمكن من تقديم أفضل تجربة لك",
    tu: "",
    ru: "",
    fr: "",
  },
  DONE: {
    en: "Done",
    ar: "تم",
    tu: "",
    ru: "",
    fr: ""
  },
  SAVE: {
    en: "Save",
    ar: "حفظ",
    tu: "",
    ru: "",
    fr: ""
  },
    ASSIGN_TO_ME: {
    en: "Assign to me",
    ar: "تعيين لي",
    tu: "",
    ru: "",
    fr: "",
  },
    SELECT_EMPLOYEE: {
    en: "Select Employee",
    ar: "اختر موظف",
    tu: "",
    ru: "",
    fr: "",
  },
  SEND_NOTIFICATION_TO_USER: {
    en: "Send Notification to user",
    ar: "ارسل اشعار للمستخدم",
    tu: "",
    ru: "",
    fr: "",
  },
  //_____________UI_SearchInput______________
  SEARCH: {
    ar: "بحث",
    en: "Search",
    tu: "Arama",
    ru: "Поиск",
    fr: "Recherche",
  },
  LOGIN: {
    ar: "تسجيل الدخول",
    en: "Log In",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  USER_NAME: {
    ar: "اسم المستخدم",
    en: "User Name",
    tu: "Kullanıcı adı",
    ru: "Имя пользователя",
    fr: "Nom d'utilisateur"
  },
  PASSWORD: {
    ar: "كلمة المرور",
    en: "Password",
    tu: "Şifre",
    ru: "Пароль",
    fr: "Mot de passe"
  },
  THIS_WEEK: {
    en: "This Week",
    ar: "هذا الأسبوع",
    tu: "Bu Hafta",
    ru: "На этой неделе",
    fr: "Cette semaine",
  },
  NEXT_WEEK: {
    en: "Next Week",
    ar: "الأسبوع القادم",
    tu: "Gelecek Hafta",
    ru: "На следующей неделе",
    fr: "La semaine prochaine",
  },
  THIS_MONTH: {
    en: "This Month",
    ar: "هذا الشهر",
    tu: "Bu Ay",
    ru: "В этом месяце",
    fr: "Ce mois-ci",
  },
  NEXT_MONTH: {
    en: "Next Month",
    ar: "الشهر القادم",
    tu: "Gelecek Ay",
    ru: "В следующем месяце",
    fr: "Le mois prochain",
  },
  LAST_MONTH: {
    en: "Last Month",
    ar: "الشهر الماضي",
    tu: "Geçen Ay",
    ru: "Прошлый месяц",
    fr: "Le mois dernier",
  },
  LAST_WEEK: {
    en: "Last Week",
    ar: "الأسبوع الماضي",
    tu: "Geçen Hafta",
    ru: "Прошлая неделя",
    fr: "La semaine dernière",
  },
  CUSTOM: {
    en: "Custom",
    ar: "مخصص",
    tu: "Özel",
    ru: "Обычай",
    fr: "Douane",
  },
  THIS_YEAR: {
    en: "This Year",
    ar: "هذا العام",
    tu: "Bu Yıl",
    ru: "В этом году",
    fr: "Cette année",
  },
  LAST_YEAR: {
    en: "Last Year",
    ar: "العام الماضي",
    tu: "Geçen Yıl",
    ru: "Прошлый год",
    fr: "L'année dernière",
  },
  NEXT_YEAR: {
    en: "Next Year",
    ar: "العام القادم",
    tu: "Gelecek Yıl",
    ru: "Следующий год",
    fr: "L'année prochaine",
  },
  ANALYTICAL_DASHBOARD: {
    en: "Analytical Dashboard",
    ar: "لوحة القيادة التحليلية",
    tu: "Gelecek Yıl",
    ru: "Следующий год",
    fr: "L'année prochaine",
  },
  STATISTICS: {
    en: "Statistics",
    ar: "الاحصائيات",
    tu: "Gelecek Yıl",
    ru: "Следующий год",
    fr: "L'année prochaine",
  },
  COUNTRY: {
    en: "Country",
    ar: "بلد",
    tu: "Gelecek Yıl",
    ru: "Следующий год",
    fr: "L'année prochaine",
  },
  TICKETS_PROBLEMS: {
    en: "Tickets Problems",
    ar: "مشاكل التذاكر",
    tu: "Gelecek Yıl",
    ru: "Следующий год",
    fr: "L'année prochaine",
  },
  TICKETS_BY_MOBILE_TYPE: {
    en: "Tickets by Mobile type",
    ar: "التذاكر حسب نوع الجوال",
    tu: "Gelecek Yıl",
    ru: "Следующий год",
    fr: "L'année prochaine",
  },
  TICKETS_BY_SOURCE: {
    en: "Tickets By Source",
    ar: "التذاكر حسب المصدر",
    tu: "Gelecek Yıl",
    ru: "Следующий год",
    fr: "L'année prochaine",
  },
  EMPLOYEE_PERFORMANCE: {
    en: "Employee Performance",
    ar: "أداء الموظفين",
    tu: "Gelecek Yıl",
    ru: "Следующий год",
    fr: "L'année prochaine",
  },
  OVERALL: {
    en: "Overall",
    ar: "الاجمالي",
    tu: "Gelecek Yıl",
    ru: "Следующий год",
    fr: "L'année prochaine",
  },
  ALL_EMAILS: {
    en: "All Emails",
    ar: "جميع رسائل البريد الإلكتروني",
    tu: "Tüm E-postalar",
    ru: "Все электронные письма",
    fr: "Tous les e-mails"
  },
  TRANSFER_TICKET: {
    en: "Transfer Ticket",
    ar: "تذكرة النقل",
    tu: "Transfer Bileti",
    ru: "Трансферный билет",
    fr: "Billet de transfert"
  },
  COMPOSE: {
    en: "Compose",
    ar: "اكتب",
    tu: "Transfer Bileti",
    ru: "Трансферный билет",
    fr: "Billet de transfert"
  },
  CLOSE_TICKET: {
    en: "Close Ticket",
    ar: "إغلاق التذكرة",
    tu: "",
    ru: "",
    fr: ""
  },
  FIRST_NAME: {
    en: "First Name",
    ar: "الاسم الاول",
    tu: "",
    ru: "",
    fr: ""
  },
  CAN_NOT_UPLOAD_MORE_THAN_TWO_FILES: {
    en: "Cannot upload more than 2 files",
    ar: "لا يمكن تحميل أكثر من ملفين",
    tu: "",
    ru: "",
    fr: ""
  },
  //______________Requests_Page_____________
  REQUESTS: {
    en: "Requests",
    ar: "الطلبات",
    tu: "",
    ru: "",
    fr: ""
  },
  LAST_NAME: {
    en: "Last Name",
    ar: "الاسم الاخير",
    tu: "",
    ru: "",
    fr: ""
  },
  REQUEST: {
    en: "Request",
    ar: "الطلب",
    tu: "",
    ru: "",
    fr: ""
  },
  RECEIVER: {
    en: "Receiver",
    ar: "المتلقي",
    tu: "",
    ru: "",
    fr: ""
  },
  COMMITED_BY: {
    en: "Committed  by",
    ar: "قام به",
    tu: "",
    ru: "",
    fr: ""
  },
  UNDO: {
    en: "Undo",
    ar: "إلغاء",
    tu: "",
    ru: "",
    fr: ""
  },
  NO_REQUESTS_TO_DISPLAY: {
    en: "There are no requests to display",
    ar: "لا يوجد طلبات لعرضها",
    tu: "",
    ru: "",
    fr: ""
  },
  FILTER_REQUESTS: {
    en: "Filter Requests",
    ar: "تصفية الطلبات",
    tu: "",
    ru: "",
    fr: ""
  },
  //_____________View_Member_Modal______________
  VIEW_MEMBER: {
    en: "View Member",
    ar: "بيانات العميل",
    tu: "",
    ru: "",
    fr: ""
  },
  DATE_OF_BIRTH: {
    en: "Date of Birth",
    ar: "تاريخ الميلاد",
    tu: "",
    ru: "",
    fr: ""
  },
  NATIONALITY: {
    en: "Nationality",
    ar: "الجنسية",
    tu: "",
    ru: "",
    fr: ""
  },
  NATIONAL_ID: {
    en: "National ID",
    ar: "الرقم القومي",
    tu: "",
    ru: "",
    fr: ""
  },
  GENDER: {
    en: "Gender",
    ar: "الجنس",
    tu: "",
    ru: "",
    fr: ""
  },
  SCHOOL: {
    ar: "المدرسة",
    en: "School",
    tu: "",
    ru: "",
    fr: "",
  },
  DATE_OF_BIRTH: {
    en: "Date of Birth",
    ar: "تاريخ الميلاد",
    tu: "",
    ru: "",
    fr: ""
  },
  COUNTRY_OF_CITIZENSHIP: {
    en: "Country of citizenship",
    ar: "بلد الجنسية",
    tu: "",
    ru: "",
    fr: ""
  },
  NATIONAL_ID_NUMBER: {
    en: "National id number",
    ar: "رقم الهوية الوطنية",
    tu: "",
    ru: "",
    fr: ""
  },
  ID_NUMBER: {
    en: "Id number",
    ar: "رقم الهوية",
    tu: "",
    ru: "",
    fr: ""
  },
  IQAMA_ID: {
    en: "Iqama id",
    ar: "رقم الإقامة",
    tu: "",
    ru: "",
    fr: ""
  },
  PASSPORT_ID: {
    en: "Passport Id",
    ar: "رقم الجواز",
    tu: "",
    ru: "",
    fr: ""
  },
  GRADE: {
    en: "Grade",
    ar: "الصف",
    tu: "",
    ru: "",
    fr: ""
  },
  CLASS: {
    en: "Class",
    ar: "الفصل",
    tu: "",
    ru: "",
    fr: ""
  },
  COUNTRY_OF_RESIDENCE: {
    en: "Country of Residence",
    ar: "بلد الاقامة",
    tu: "",
    ru: "",
    fr: ""
  },
  COUNTRY_OF_RESIDENCE: {
    en: "Country of Residence",
    ar: "بلد الإقامة",
    tu: "",
    ru: "",
    fr: ""
  },
  GOVERNORATE: {
    en: "Governate",
    ar: "المحافظة",
    tu: "",
    ru: "",
    fr: ""
  },
  CITY: {
    en: "City",
    ar: "المدينة",
    tu: "",
    ru: "",
    fr: ""
  },

  //____________________Undo_Request_Modal________________________
  UNDO_REQUEST: {
    en: "Undo Request",
    ar: "التراجع عن الطلب",
    tu: "",
    ru: "",
    fr: ""
  },
  STREET: {
    en: "Street",
    ar: "الشارع",
    tu: "",
    ru: "",
    fr: ""
  },
  ARE_YOU_SURE_YOU_WANT_TO_UNDO_THIS_REQUEST: {
    en: "Are You Sure you want to Undo this Request ?",
    ar: "هل أنت متأكد أنك تريد التراجع عن هذا الطلب؟",
    tu: "",
    ru: "",
    fr: ""
  },
  NO_MORE_OPTIONS : {
    en: "No more options",
    ar: "لا توجد المزيد من الخيارات",
    tu: "",
    ru: "",
    fr: ""
  },
  YES: {
    en: "Yes",
    ar: "نعم",
    tu: "",
    ru: "",
    fr: ""
  },
  FIRST_GUARDIAN : {
    en: "First Guardian",
    ar: "ولي الأمر الأول",
    tu: "",
    ru: "",
    fr: ""
  },
  SECOND_GUARDIAN : {
    en: "Second Guardian",
    ar: "ولي الأمر الثاني",
    tu: "",
    ru: "",
    fr: ""
  },
  RELATION: {
    en: "Relation",
    ar: "العلاقة",
    tu: "",
    ru: "",
    fr: ""
  },
  NO: {
    en: "No",
    ar: "لا",
    tu: "",
    ru: "",
    fr: ""
  },
//_________________Security_Page_____________________
USERS_PASSWORDS: {
  en: "User Passwords",
  ar: "كلمات مرور المستخدم",
  tu: "",
  ru: "",
  fr: ""
},
USERS_PASSWORD: {
  en: "User Password",
  ar: "كلمة مرور المستخدم",
  tu: "",
  ru: "",
  fr: ""
},
SUB_USER_PASSWORD: {
  en: "Sub user Password",
  ar: "كلمة مرور المستخدم الفرعي",
  tu: "",
  ru: "",
  fr: ""
},
FORGET_PASSWORD: {
  en: "Forget Password",
  ar: "نسيت كلمة المرور",
  tu: "",
  ru: "",
  fr: ""
},
CHANGE_PASSWORD: {
  en: "Change  Password",
  ar: "تغيير كلمة المرور",
  tu: "",
  ru: "",
  fr: ""
},
SMS_PROVIDER: {
  en: "SMS Provider",
  ar: "مقدم خدمة الرسائل القصيرة ",
  tu: "",
  ru: "",
  fr: ""
},
CHECK_BALANCE: {
  en: "Check Balance",
  ar: "التحقق من الرصيد",
  tu: "",
  ru: "",
  fr: ""
},
SENT_ON: {
  en: "Sent on",
  ar: "أرسلت في",
  tu: "",
  ru: "",
  fr: ""
},
ERROR: {
  en: "Error",
  ar: "خطأ",
  tu: "",
  ru: "",
  fr: ""
},
PROVIDER: {
  en: "Provider",
  ar: "مقدم الخدمة",
  tu: "",
  ru: "",
  fr: ""
},
THERE_ARE_NO_PROVIDERS: {
  en: "There are no providers",
  ar: "لا يوجد مقدمين خدمة",
  tu: "",
  ru: "",
  fr: ""
},
ARE_YOU_SURE_YOU_WANT_TO_FORGET_PASSWORD: {
  en: "Are You Sure you want to Forget Password ?",
  ar: "هل أنت متأكد أنك تريد أن تغير كلمة المرور ؟",
  tu: "",
  ru: "",
  fr: ""
},
OUR_BALANCE: {
  en: "Our Balance",
  ar: "رصيدنا",
  tu: "",
  ru: "",
  fr: ""
},
MESSAGES: {
  en: "Messages",
  ar: "رسالة",
  tu: "",
  ru: "",
  fr: ""
},
AVG: {
  en: "Avg",
  ar: "متوسط",
  tu: "",
  ru: "",
  fr: ""
},
//________________Ticket_Details_Transfer_Ticket____________

TRANSFER: {
  en: "Transfer",
  ar: "تحويل",
  tu: "",
  ru: "",
  fr: ""
},
ENTER_COMMENT: {
  en: "Enter Comment",
  ar: "أدخل تعليق",
  tu: "",
  ru: "",
  fr: ""
},
//________________Ticket_Details_Close_Ticket_______________
HOID_TICKET: {
  en: "Hold Ticket",
  ar: "تعليق التذكرة",
  tu: "",
  ru: "",
  fr: ""
},
ARE_YOU_SURE_YOU_WANT_TO_CLOSE_THIS_TICKET: {
  en: "Are You Sure you want to Close this Ticket?",
  ar: "هل أنت متأكد أنك تريد إغلاق هذه التذكرة؟",
  tu: "",
  ru: "",
  fr: ""
},

//________________Organization_Info_Branches_page____________

CREARED: {
  en: "Created",
  ar: "تم انشائها",
  tu: "",
  ru: "",
  fr: ""
},
NAME: {
  en: "Name",
  ar: "الإسم",
  tu: "",
  ru: "",
  fr: ""
},
BRANCH_INFO: {
  en: "Branch Info",
  ar: "بيانات الفرع",
  tu: "",
  ru: "",
  fr: ""
},
DELETE_BRANCH: {
  en: "Delete Branch",
  ar: "حذف الفرع",
  tu: "",
  ru: "",
  fr: ""
},
ARE_YOU_SURE_YOU_WANT_TO_DELETED_THIS_BRANSH: {
  en: "Are you sure you want to delete this Branch ? this action is irreversible!",
  ar: "هل أنت متأكد أنك تريد حذف هذا الفرع؟ هذا الإجراء لا رجعة فيه!",
  tu: "",
  ru: "",
  fr: ""
},

//________________viw_Branch_Info______________
EDUCATION_TYPE: {
  en: "Education type ",
  ar: "نوع التعليم",
  tu: "",
  ru: "",
  fr: ""
},
NO_OF_STUDENTS: {
  en: "N.Students",
  ar: "عدد الطلاب",
  tu: "",
  ru: "",
  fr: ""
},
EDIT_BRANCH: {
  en: "Edit Branch",
  ar: "تعديل الفرع",
  tu: "",
  ru: "",
  fr: ""
},
BRANCH_NAME: {
  en: "Branch Name",
  ar: "اسم الفرع",
  tu: "",
  ru: "",
  fr: ""
},
NO_OF_GRADES: {
  en: "N.Grades",
  ar: "عدد الصفوف",
  tu: "",
  ru: "",
  fr: ""
},
NO_OF_MEMBER: {
  en: "N. Members",
  ar: "عدد الأعضاء",
  tu: "",
  ru: "",
  fr: ""
},
NO_OF_CLASSES: {
  en: "N. Classes",
  ar: "عدد الفصول",
  tu: "",
  ru: "",
  fr: ""
},
REDIREDT_TO_SCHOOL: {
  en: "Redirect to School",
  ar: "إعادة التوجيه إلى المدرسة",
  tu: "",
  ru: "",
  fr: ""
},

}

export default words;